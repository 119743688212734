export default class AdminsForm {

    constructor() {
        this.dropifyPicture = null;
        this.dropifyLogo = null;
        this.role_select = null;
        this.bindEvents();
        //window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
        var dropifyConfig = {
            messages: {
                'default': I18n.t('plugins.dropify.messages.default'),
                'replace': I18n.t('plugins.dropify.messages.replace'),
                'remove':  I18n.t('plugins.dropify.messages.remove'),
                'error':   I18n.t('plugins.dropify.messages.error')
            },
            error: {
                'fileSize': I18n.t('plugins.dropify.messages.fileSize'),
                'minWidth': I18n.t('plugins.dropify.messages.minWidth'),
                'maxWidth': I18n.t('plugins.dropify.messages.maxWidth'),
                'minHeight': I18n.t('plugins.dropify.messages.minHeight'),
                'maxHeight': I18n.t('plugins.dropify.messages.maxHeight'),
                'imageFormat': I18n.t('plugins.dropify.messages.imageFormat'),
                'fileExtension': I18n.t('plugins.dropify.messages.fileExtension')
            }
        };

        this.dropifyPicture = $('#profile_image').dropify(dropifyConfig);
        this.dropifyLogo = $('#entity_logo').dropify(dropifyConfig);

        get_logo_url

        this.role_select = $('#admin_role_id').selectpicker();
    }

    onDataChanged(data) {
    }

    onDestroy() {
        this.role_select.selectpicker('destroy');
        this.dropifyPicture.data('dropify').destroy();
        this.dropifyLogo.data('dropify').destroy();
    }

}