export default class UserAgentIndex {

    constructor() {
        this.bindEvents();
    }


    bindEvents() {
        $(".js-edit-agent").click(this.loadUpdateModal);
    }

    loadUpdateModal(e) {
        let id = $(this).data("id");
        let ajaxData = {
            url: "/user_agents/" + id + "/load_update_form",
            method: "GET"
        };

        $.ajax(ajaxData).done(function(html) {
            $('#update_modal_content').empty().append(html);
            $('#update_user_agent_modal').modal("show");
        })
    }

    onDataChanged(data) {
    }

    onDestroy() {
    }
}
