export default class AlertForm {


    constructor() {
        this.bindEvents();
        this.alert_type = "user";
    }


    bindEvents() {
        var self = this;


        $(window).ready(function() {
          let minfollowed = $("#minfollowed").prop('selectedIndex');
          let maxfollowed = $("#maxfollowed").prop('selectedIndex');
          if (typeof jQuery.ui !== 'undefined'){
            $( "#slider" ).slider({
              range: true,
              min: 0,
              max: 5,
              values: [minfollowed, maxfollowed+1],
              step: 1,
              slide: function( event, ui ) {
                if(ui.values[ 0 ] >= ui.values[ 1 ]){
                  return false;
                }
              }
            });
          }
        if($("#select_languages").find(":selected").length > 0 ){
          $("#select_language_toggle").click();
          self.set_english_language();
          self.set_french_language();
        }

        self.load_user_alert_content();
        self.disable_wrong_options($("#maxfollowed"));
        self.disable_wrong_options($("#minfollowed"));
        self.disable_wrong_options($("#maxfollow"));
        self.disable_wrong_options($("#minfollow"));

      });

        $( "#slider" ).on( "slidechange", function( event, ui ) {
          if(!$('#expert-mode-toggle').is(':checked')){
           self.set_form_values();
           console.log("on update le form");
          }
        });


        // $(".btn-alert-type").on("click", function(e) {
        //     e.preventDefault();
        //
        //     var alertType = $(this).data("alert_type");
        //
        //     $("#alert-type-part").addClass("d-none");
        //     $("#alert_form").removeClass("d-none");
        //
        //     $("#alert_alert_type").val(alertType);
        //      // self.updateFormDisplay();
        // });

        $('.js-new-alert-toggle-type').unbind("click").click(function(){
          self.transition();
          self.update_display();

        });
        $('.js-alert-type-button').unbind("click").click(function(){
          self.change_selection(this);
        });

        $(".js-followed_parameter").on('change', function(){
          if($('#expert-mode-toggle').is(':checked')){
           self.set_slider_values();
          }
          self.disable_wrong_options(this);
        });

        $(".js-follow_parameter").on('change', function(){
          self.disable_wrong_options(this);
        });



        $('#expert-mode-toggle').change(function(){
          if (this.checked){
            $('.expert-mode-form').removeClass('d-none');
            $('.follower-slider').addClass('d-none');
          }else{
            $('.expert-mode-form').addClass('d-none');
            $('.follower-slider').removeClass('d-none');
            $($("#minfollow").find("option")[0]).prop('selected', true).change();
            $($("#maxfollow").find("option")[4]).prop('selected', true).change();

          }
        });


        $('#select_language_toggle').change(function(){
          if (this.checked){
            $('.language-select-form').removeClass('d-none');
            $("#include_unknown_languages").prop("checked", true);

          }else{
            $("#select_languages option:selected").prop("selected", false).change();
            $('.language-select-form').addClass('d-none');
          }
        });


        $('.js-load-modal-alert-name').unbind("click").click(function(){
          self.set_form_values();
          if (!("#expert-mode-toggle").checked){
          }
          self.load_alert_name_modal();
        });

        $('#include_french_checkbox').unbind("click").click(function(){
          if (this.checked){
            $("#select_languages option:contains(français)").prop('selected', true).change();
          }else{
            $("#select_languages option:contains(français)").prop('selected', false).change();
          }
        });

        $('#include_english_checkbox').unbind("click").click(function(){
          if (this.checked){
            $("#select_languages option:contains(anglais)").prop('selected', true).change();
          }else{
            $("#select_languages option:contains(anglais)").prop('selected', false).change();
          }
        });

        $("#select_languages").change(function() {
          self.set_english_language();
          self.set_french_language();
        });

        // self.updateFormDisplay();

    }

    // updateFormDisplay() {
    //     var alertType = $("#alert_alert_type").val();
    //     if(alertType=="alert_type_influencers") {
    //         $(".alert_type_related_hashtag").addClass("d-none");
    //         $(".alert_type_anomaly").addClass("d-none");
    //         $(".alert_type_influencers").removeClass("d-none");
    //
    //     }
    //     else if(alertType=="alert_type_related_hashtag") {
    //         $(".alert_type_influencers").addClass("d-none");
    //         $(".alert_type_anomaly").addClass("d-none");
    //         $(".alert_type_related_hashtag").removeClass("d-none");
    //     }
    //
    //     else if(alertType=="alert_type_anomaly") {
    //         $(".alert_type_influencers").addClass("d-none");
    //         $(".alert_type_related_hashtag").addClass("d-none");
    //         $(".alert_type_anomaly").removeClass("d-none");
    //     }
    // }

    update_display(){
      let self = this
      if(self.alert_type=="user") {
        $(".new_alert_hashtag").addClass("d-none");
        $(".new_alert_user").removeClass("d-none");
      }
      else if(self.alert_type=="hashtag") {
        $(".new_alert_user").addClass("d-none");
        $(".new_alert_hashtag").removeClass("d-none");
      }
  }

    transition(){
      let self = this
      if (self.alert_type == "user"){
        self.alert_type = "hashtag"
        $('#new_alert_type_knob').stop(true).animate({
          left: "50%",
        }, 200, 'swing');
          if ($(window).width() >= 960) {
            $("#selected_alert_type_wide").html("<i class='fas fa-hashtag mr-2' style='font-size:24px;'></i>M'alerter sur un Hashtag suivi");
          }else {
            $("#selected_alert_type_small").html("<i class='fas fa-hashtag mr-2' style='font-size:24px;'></i>Sur un Hashtag suivi");
          }
      }else if (self.alert_type == "hashtag"){
        $('#new_alert_type_knob').stop(true).animate({
          left: "0%",
        }, 200, 'swing');
        self.alert_type = "user"
        if ($(window).width() >= 960) {
          $("#selected_alert_type_wide").html("<i class='fas fa-at mr-2' style='font-size:24px;'></i>M'alerter sur un Compte suivi");
        }else {
          $("#selected_alert_type_small").html("<i class='fas fa-at mr-2' style='font-size:24px;'></i>Sur un Compte suivi");
        }
      }
    }

    change_selection(btn){
      let self = this
      let input = $(btn).find("input");
      let card = $(btn);
        input.prop("checked", true);
        $(".alert_type_card.border-primary").removeClass("border-primary").addClass('border-muted');
        card.removeClass("border-muted");
        card.addClass("border-primary");
        self.update_display_form();
        if(input.attr('id') == "alert_type_anomaly"){
        $("#wanted_hashtags option:selected").prop("selected", false).change();
        $($("#send_without_data").find("option")[1]).prop('selected', true).change();
      }
    }

    update_display_form(){
      let checked = $("input:checked").first();
      if ($(checked).val() == "alert_type_related_hashtag"){
        $(".alert_type_related_hashtag").removeClass("d-none");
      }else{
        $(".alert_type_related_hashtag").addClass("d-none");
      }
    }

    load_alert_name_modal(){
      $("#enter_alert_name").modal();
    }

    set_form_values(){
        $($("#minfollowed").find("option")[$("#slider").slider("values",0)]).prop('selected', true).change();
        $($("#maxfollowed").find("option")[$("#slider").slider("values",1)-1]).prop('selected', true).change();
    }

    set_slider_values(){
      $("#slider").slider("values",0, $("#minfollowed").prop('selectedIndex'));
      $("#slider").slider("values",1, ($("#maxfollowed").prop('selectedIndex')+1));



    }

    set_english_language(){
      if($("#select_languages option:contains(anglais)").is(':selected')){
        $('#include_english_checkbox').prop("checked", true);
      }else{
        $('#include_english_checkbox').prop("checked", false);
      }
    }

    set_french_language(){
      if($("#select_languages option:contains(français)").is(':selected')){
        $('#include_french_checkbox').prop("checked", true);
      }else{
        $('#include_french_checkbox').prop("checked", false);
      }
    }

    load_user_alert_content(){
      if($("#alert_type_related_hashtag_form").is(':checked')){
        $("#alert_type_related_hashtag_card").click();
        console.log("on click");
      }else{
        $("#alert_type_anomaly_card").click();
      }
    }

    disable_wrong_options(btn){

      let val =  Number($(btn).val());
      if($(btn).hasClass("js-followed_parameter")){
        if($(btn).attr('id') == "minfollowed"){
            $('#maxfollowed option').each(function() {
              $(this).prop('disabled', Number($(this).val()) <= val)
          })
        }else{
            $('#minfollowed option').each(function() {
              $(this).prop('disabled', Number($(this).val()) >= val)
          })
        }
      }else{
        if($(btn).attr('id') == "minfollow"){
            $('#maxfollow option').each(function() {
            $(this).prop('disabled', Number($(this).val()) <= val)
          })
        }else{
          $('#minfollow option').each(function() {
          $(this).prop('disabled', Number($(this).val()) >= val)
          })
        }
      }
    }


}
