import ApexCharts from 'apexcharts'
import { ajax } from 'jquery';

export default class AnomaliesTable {
  
  constructor() {
      this.user_id = $("#tabs-card").data("user_id");
      this.bindEvents();
      this.showChart();
      this.chart = this.showChart();
      this.chart.render();
    }

  bindEvents() {
    self = this;
    var username = document.getElementById("stats-datatable").getAttribute("data-username")

    this.dataTable = $('#stats-datatable').DataTable({
      "info": false,
      "lengthChange": false,
      "searching": false,
      "order": [[0, "desc"]],
      "language": {
          url: '/assets/data-tables/locales/' + I18n.locale + '.json',
          previous: "<i class='mdi mdi-chevron-left'>",
          next: "<i class='mdi mdi-chevron-right'>"
      },
      drawCallback: function () {
          $(".dataTables_paginate > .pagination").addClass("pagination-rounded js-save-order")
      },
      dom: 'Bfrtip',
      buttons: [
        {
          extend: 'csvHtml5',
          className: "btn btn-outline-secondary pill btn-xs btn-1",
          text: "export csv",
          title: username,
          init: function(api, node, config) {
              $(node).removeClass('btn-secondary');
            }
          }
        ]
    });

    this.dataTable.on('draw', function () {
      $(".btn-1").appendTo($(".export-btn-1"));
    });

    this.postsDataTable = $('#posts-datatable').DataTable({
      "info": false,
      "lengthChange": false,
      "searching": false,
      "order": [[0, "desc"]],
      "language": {
          url: '/assets/data-tables/locales/' + I18n.locale + '.json',
          previous: "<i class='mdi mdi-chevron-left'>",
          next: "<i class='mdi mdi-chevron-right'>"
      },
      drawCallback: function () {
          $(".dataTables_paginate > .pagination").addClass("pagination-rounded js-save-order")
      },
      dom: 'Bfrtip',
      buttons: [
        {
          extend: 'csvHtml5',
          className: "btn btn-outline-secondary pill btn-xs btn-2",
          text: "export csv",
          title: username,
          init: function(api, node, config) {
              $(node).removeClass('btn-secondary');
            }
          }
        ]
    });

    this.postsDataTable.on('draw', function () {
      $(".btn-2").appendTo($(".export-btn-2"));
    });

    if (window.localStorage.userStatHistoryOrder !== undefined) {
        let order = window.localStorage.userStatHistoryOrder.split(",")
        this.dataTable.order(order[1], order[2])
    }

    $("js-save-order").click(this.saveOrder)

    $(".show-chart-btn").on("click", function(e) {
      self.getDataForChart(e.currentTarget.getAttribute('data-b-date'), e.currentTarget.getAttribute('data-e-date'), e.currentTarget.getAttribute('data-anomaly-date'), e.currentTarget.getAttribute('data-name'));
    });

    $("#chartModalCenter").on('hidden.bs.modal', function () {
      $("#chartModalTitle").html("");
    });

    // chart modal anomaly elements modal
    $("#anomalyModal").on('shown.bs.modal', function () {
      $("#chartModalCenter").modal('hide');
    });
    
    $("#anomalyModal").on('hidden.bs.modal', function () {
      $("#chartModalCenter").modal('show');
    });
  }

    saveOrder() {
        window.localStorage.userStatHistoryOrder = application.current_handler.DataTable.order().join()
    }

    onDataChanged(data) {
    }

    onDestroy() {
        this.dataTable.destroy();
        this.dataTable = null;
        this.postsDataTable.destroy();
        this.postsDataTable = null;
    }

    getDataForChart(begin_date, end_date, anomaly_date, anomaly_type){
      var self = this;
      $.ajax({
        url: '/users/' + this.user_id +  '/stats-bewteen-dates?b_date=' + begin_date + '&e_date=' + end_date,
        method: "GET",
        dataType: "json",
      })
      .done(function (response) {
       
        var labels = response.labels;
        var newFollowers = response.new_followers;
        var newFollowing = response.new_following;
        var json = "[";

        if (anomaly_type == "abonnés"){
          if(response.follower_anomalies.includes(10)){
            json += '{"seriesIndex": ' + 0 + ',"dataPointIndex":' + 10 + ',"fillColor": ' + '"#ffffff"' + ',"strokeColor": ' + '"#FF0000"' + ',"size": 7,"shape": "circle"},';
            response.follower_anomalies.splice(response.follower_anomalies.indexOf(10), 1);
          }
        }

        // add marker for each anomaly
        for (var i = 0; i < response.follower_anomalies.length; i++) {
          json += '{"seriesIndex": ' + 0 + ',"dataPointIndex":' + response.follower_anomalies[i] + ',"fillColor": ' + '"#ffffff"' + ',"strokeColor": ' + '"#cecece"' + ',"size": 7,"shape": "circle"},';
        }

        if (anomaly_type == "abonnements"){
          if(response.following_anomalies.includes(10)){
            json += '{"seriesIndex": ' + 1 + ',"dataPointIndex":' + 10 + ',"fillColor": ' + '"#ffffff"' + ',"strokeColor": ' + '"#FF0000"' + ',"size": 7,"shape": "circle"},';
            response.following_anomalies.splice(response.following_anomalies.indexOf(10), 1);
          }
        }

        // add marker for each anomaly
        for (var i = 0; i < response.following_anomalies.length; i++) {
          json += '{"seriesIndex": ' + 1 + ',"dataPointIndex":' + response.following_anomalies[i] + ',"fillColor": ' + '"#ffffff"' + ',"strokeColor": ' + '"#cecece"' + ',"size": 7,"shape": "circle"},';
        }
        
        json = json.slice(0, -1);
        json += "]"
        json = JSON.parse(json);

        $("#chartModalTitle").html("Anomalie d'"+ anomaly_type + " " + anomaly_date);

        self.updateChart(self.chart, newFollowers, newFollowing, json, labels);
      });
      
      
    }

    showChart(){
      var options = {
        series: [{
          name: 'Abonnés',
          data: []
        }, {
          name: 'Abonnements',
          data: []
        }],  
        chart: {
          height: 350,
          type: 'area',
          toolbar: {
            show: false
          },
          zoom: {
              enabled: false,
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          type: 'category',
          categories: []
        },
      };

      var chart = new ApexCharts(document.querySelector("#chart"), options);
      return chart;
    }

    updateChart(chart, newFollowers, newFollowings, markers_json, labels){
      self = this;
      var w = window.innerWidth;
      var tickamount = 15;
      if (w < 600){
        tickamount = 10;
      }
      if (w < 500){
        tickamount = 5;
      }
      chart.updateSeries([{
        name: 'Abonnés',
        data: newFollowers
      }, {
        name: 'Abonnements',
        data: newFollowings
      }]);

      chart.updateOptions({
        chart: {
          events: {
            dataPointSelection: (event, chartContext, config) => {
              var serie = 0;
              if(config.selectedDataPoints[0] == null){
                serie = 1;
              }else{
                serie = 0;
              }

              var url = "/users/"+this.user_id+"/posts-and-stories-by-day?date=" + labels[config.selectedDataPoints[serie]].replace(/\//g, "-")

              $.ajax({
                url: url,
                method: "GET",
                dataType: "json"
              })
              .done(function (response) {
                var posts = response.posts;
                var posts_eng_rates = response.posts_eng_rates;
                var stories_thumbnails = response.stories_thumbnails
                var previous_date = response.previous_date
                var previous_day_posts_count = response.previous_day_posts_count
                var previous_day_stories_count = response.previous_day_stories_count

                //var previous_day_posts = response.previous_day_posts;
                //var previous_day_stories = response.previous_day_stories;
                
                var html = "<div class='container'>";
                if (posts.length > 0){
                  html += "<h5>Publications</h5><div class='d-flex w-100' style='overflow-x: scroll;overflow-y: hidden;white-space: nowrap;'>";
                  for(var i = 0; i < posts.length; i++)
                  { 
                    $.ajax({
                      url: "/users/" + self.user_id + "/post-html?post_id=" + posts[i].id,
                      method: "GET",
                      dataType: "html",
                      async: false,
                    }).done(function (response) {
                      html+=response;
                    });
                  }
                  html += '</div></div>';
                }
                
                if (stories_thumbnails.length > 0){
                  if(posts.length > 0){
                    html += "<hr>";
                  }
                  html += "<h5>Stories</h5><div class='d-flex w-100' style='overflow-x: scroll;overflow-y: hidden;white-space: nowrap;'>";
                  for(var i = 0; i < stories_thumbnails.length; i++)
                  {
                    html += "<div class='mt-2 mb-4 ml-3'>"
                    html += '<img src="' + stories_thumbnails[i] + '" style="border-radius:14px; height:400px; width:250px" alt="story image">';
                    html += "</div>";
                  }
                  html += '</div>';
                }

                if (stories_thumbnails.length + posts.length < 1){
                  html += "<div class='row'><h5>Nous n'avons pas trouvé de publications ni de stories pour ce jour</h5></div>";
                  
                  if(previous_day_posts_count > 0){
                    html += "<div class='row'><div class='col'>";
                    html += "<a href='/users/" + self.user_id + "/posts?posted_at=" + previous_date + "' target='blank__' class='btn btn-primary btn-block'>Voir les publications de la veille</a>";
                    html += "</div>";
                  }

                  if(previous_day_stories_count > 0){
                    html += "<div class='col'>";
                    html += "<a href='/users/" + self.user_id + "/stories?posted_at=" + previous_date + "' target='blank__' class='btn btn-primary btn-block'>Voir les stories de la veille</a>";
                    html += "</div></div>";
                  }

                  if ($( "#modal-dialog" ).hasClass('modal-lg')) {
                    $( "#modal-dialog" ).removeClass( 'modal-lg');
                  }
                
                }else{
                  if (!$( "#modal-dialog" ).hasClass('modal-lg')) {
                    $( "#modal-dialog" ).addClass( 'modal-lg');
                  }
                }
                
                html+="</div>";

                if(posts.length > 0 && stories_thumbnails.length > 0){
                  $('#anomalyModal .modal-body').css({'overflow-x':'auto'}).css({'height':'auto'});
                  $('#anomalyModal .modal-body').css({'overflow-x':'scroll'}).css({'height':'600px'});
                }else{
                  $('#anomalyModal .modal-body').css({'overflow-x':'auto'}).css({'height':'auto'});
                }

                $('#anomalyModal .modal-title').html("Éléments publiés le " + labels[config.selectedDataPoints[serie]]);
                $('#anomalyModal .modal-body').html(html);
                $('#anomalyModal').modal('show');

                $(".confirmation-modal").on('hidden.bs.modal', function () {
                  $('#anomalyModal .modal-body').empty();
                });
              })
            }
          }
        },
        xaxis: {
          tickAmount: tickamount,
          type: 'category',
          categories: labels
        },
        markers: {
          discrete: markers_json
        },
      });
      $('#chartModalCenter').modal('show');
    }

    numberWithSpaces(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
}
