import ListManager from "../utils/ListManager";

export default class HashtagSelectionInfluencers {

  constructor() {
      const listManager = new ListManager();
      this.bindEvents();
  }

  bindEvents() {
  }

  onDataChanged(data) {
  }

  onDestroy() {
  }
}