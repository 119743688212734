export default class HashtagPosts {
    
    constructor() {

        this.bindEvents();

    }

    bindEvents() {
        $("#related_hashtag_selection").on("change", function(e) {
          Turbolinks.visit($(this).find("option:selected").data("url"));
        });

        $("#alert_selection").on("change", function(e) {
          Turbolinks.visit($(this).find("option:selected").data("url"));
        });
    }
}