export default class DashboardIndex {

    constructor() {
        this.bindEvents();
        //window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
    }

    onDataChanged(data) {
    }

    onDestroy() {
    }

}