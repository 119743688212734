export default class ProxiesIndex {

    constructor() {
        this.bindEvents();
        let thisClass = this
    }


    bindEvents() {
      let self = this
        $(".js-edit-proxy").click(function(e){
          self.loadUpdateModal(this);

        })

        $(".js-info-proxy").click(function(e){
          self.loadInfoModal(this);

        })

        $(document).on("click", '.js-copy-content', function(e){
          if(this.id == "copy-sock5-button"){
            var elem  = "#sock5_address";
          }else if (this.id == "copy-http-button"){
            var elem  = "#http_address";
          }else{
            var elem  = "#ip_change_link";
          }
          self.copyToClipboard(elem);
        })

        $(".js-edit-proxies_max_actions").click(function(e){
          self.loadMaxActionsModal(this);

        })


        // $(".js-copy-content").click(function(e){
        //   console.log("test")
        //   let elem = "#sock5_address"
        //   self.copyToClipboard(elem);
        //
        // })

        // $("#copy-link-button").unbind('click').click(function(e){
        //   let id = $(this).data("id");
        //   console.log(id);
        //   var $temp = $("<input>");
        //   $("body").append($temp);
        //   $temp.val($("#sock5_address_span"+id).text()).select();
        //   document.execCommand("copy");
        //   $temp.remove();
        // })
    }

    loadUpdateModal(btn) {
        let id = $(btn).data("id");
        let ajaxData = {
            url: "/proxies/" + id + "/load_update_form",
            method: "GET"
        };

        $.ajax(ajaxData).done(function(html) {
            $('#update_modal_content').empty().append(html);
            $('#update_modal_content select.select2-with-search').select2({
                placeholder: $('#update_modal_content select.select2-with-search').data("placeholder"),
                minimumResultsForSearch: 5
            });
            $('#update_proxy_modal').modal("show");
        })
    }


    loadInfoModal(btn) {
      let id = $(btn).data("id");
      let ajaxData = {
          url: "/proxies/" + id + "/load_info_modal",
          method: "GET"
        };

        $.ajax(ajaxData).done(function(html) {
            $('#info_proxy_content').empty().append(html);
            $('#info_proxy_modal').modal("show");
        })
    }

    copyToClipboard(element) {
      var $temp = $("<input value=''>");
      $("body").append($temp);
      $temp.attr("value", $(element).text())
      $temp.focus();
      $temp.select();
      document.execCommand('copy')
      $temp.remove();

    }

    loadMaxActionsModal(btn){
      let self = this
      let id = $(btn).data("id");
      let ajaxData = {
          url: "/proxies/" + id + "/load_max_actions_modal",
          method: "GET"
        };

        $.ajax(ajaxData).done(function(html) {
            $('#max_action_content').empty().append(html);
            $('#max_actions_modal').modal("show");
            self.bindAjaxEvent();
        })

    }

    bindAjaxEvent(){

      $("#max_actions_range").on("input", function(){
        let max_actions = $("#max_actions_range").val();
        $("#max_actions_value").html(""+max_actions);
      })

      // $("#max_actions_confirm").unbind("click").click(function() {
      //   let max_actions = $("#max_actions_range").val();
      //   let link = $("#max_actions_confirm").data("href");
      //   $("#max_actions_confirm").attr("href", link.replace("__ACTIONS_VALUE__", max_actions));
      // })

    }




    onDataChanged(data) {
    }

    onDestroy() {
    }
}
