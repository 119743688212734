export default class SmsObjectIndex {

  constructor() {
      this.bindEvents();
      let thisClass = this
  }


  bindEvents() {
    let self = this

    $(".js-copy-content").click(function(e){
      let id = $(this).data("id");
      var elem = $("#code_element_"+id);
      $(".js-copy-content").each(function(e){
          $(this).attr('data-original-title', "Copier code");
      })
      $(this).attr('data-original-title', "Copié").tooltip('show');
      self.copyToClipboard(elem);
    })
  }
    copyToClipboard(element){
      var $temp = $("<input value=''>");
      $("body").append($temp);
      $temp.attr("value", $(element).text())
      $temp.focus();
      $temp.select();
      document.execCommand('copy')
      $temp.remove();

    }
    //   $(".js-edit-proxy").click(function(e){
    //     self.loadUpdateModal(this);
    //
    //   })
    //
    //   $(".js-info-proxy").click(function(e){
    //     self.loadInfoModal(this);
    //
    //   })
    //
    //   $(document).on("click", '.js-copy-content', function(e){
    //     if(this.id == "copy-sock5-button"){
    //       var elem  = "#sock5_address";
    //     }else if (this.id == "copy-http-button"){
    //       var elem  = "#http_address";
    //     }else{
    //       var elem  = "#ip_change_link";
    //     }
    //     self.copyToClipboard(elem);
    //   })
    //
    //   $(".js-edit-proxies_max_actions").click(function(e){
    //     self.loadMaxActionsModal(this);
    //
    //   })




}
