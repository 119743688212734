export default class AdvertisingCampaignIndex {
  constructor() {
    this.bindEvents();
  }

  bindEvents() {
    self = this;

    var page_length = 10;

    this.dataTable = $('#campaigns-datatable').DataTable({
      "info": false,
      "lengthChange": false,
      "pageLength": page_length,
      "searching": false,
      "language": {
          url: '/assets/data-tables/locales/' + I18n.locale + '.json',
          previous: "<i class='mdi mdi-chevron-left'>",
          next: "<i class='mdi mdi-chevron-right'>"
      },
      drawCallback: function () {
          $(".dataTables_paginate > .pagination").addClass("pagination-rounded js-save-order")
      },
      dom: 'Bfrtip',
      buttons: [
        
        ],
        "aoColumnDefs": [
          { "bSortable": false, "aTargets": [0, 6 ] }
        ],
    });
  }

  onDestroy() {
    this.dataTable.destroy();
    this.dataTable = null;
  }
}