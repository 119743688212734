export default class AdvertisingCampaignForm {
  constructor() {
    this.dropifyPicture = null;
    this.dropifyLogo = null;
    this.bindEvents();
    //window.application.setOnDataChangeListener(this);
}

  bindEvents() {
      // tinymce.init({
      //   selector: '.textarea',
      //   height: 300, 
      //   plugins: 'advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code help wordcount',
      //   toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
      //   content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
      // });
      var self = this;

      var dropifyConfig = {
          messages: {
              'default': I18n.t('plugins.dropify.messages.default'),
              'replace': I18n.t('plugins.dropify.messages.replace'),
              'remove':  I18n.t('plugins.dropify.messages.remove'),
              'error':   I18n.t('plugins.dropify.messages.error')
          },
          error: {
              'fileSize': I18n.t('plugins.dropify.messages.fileSize'),
              'minWidth': I18n.t('plugins.dropify.messages.minWidth'),
              'maxWidth': I18n.t('plugins.dropify.messages.maxWidth'),
              'minHeight': I18n.t('plugins.dropify.messages.minHeight'),
              'maxHeight': I18n.t('plugins.dropify.messages.maxHeight'),
              'imageFormat': I18n.t('plugins.dropify.messages.imageFormat'),
              'fileExtension': I18n.t('plugins.dropify.messages.fileExtension')
          }
      };

    this.dropifyPicture = $('#profile_image').dropify(dropifyConfig);
    this.dropifyPicture = $('#logo_image').dropify(dropifyConfig);
    this.dropifyLogo = $('#entity_logo').dropify(dropifyConfig);
    
    // ------ bonus ------
    var values = [];

    $("#plus-button").on("click", function() {
      self.addBonusInput(null);
    });

    $("#bonus").on("click", ".remove-button", function() {
      self.removeBonusInput(this);
    });

    if ($(".bonus_content").length < 1) {
      $(".bonus-switch").prop("checked", true)
    }else{
      $(".bonus-switch").prop("checked", false)
    }

    $(".bonus-switch").on("click", function() {
      if (!$(this).is(":checked")) {
        $("#bonus, #plus-button").hide();
        // remove all bonus inputs
        $(".bonus_content").each(function() {
          $(this).remove();
        });
      }else{
        $("#bonus, #plus-button").show();
        if ($(".bonus-input").length < 1) {
          $("#plus-button").trigger("click");
        }
      }
    }).trigger("click");

    // Initialize bonus values and inputs
    $("input[name^='bonus']").each(function() {
      values.push($(this).val());
    });

    // ------ conditions (same structure as bonus) ------
    var conditions_values = [];

    $("#condition-plus-button").on("click", function() {
      self.addConditionInput(null);
    });

    $("#conditions").on("click", ".condition-remove-button", function() {
      self.removeConditionInput(this);
    });

    if ($(".condition_content").length < 1) {
      $(".conditions-switch").prop("checked", true)
    }else{
      $(".conditions-switch").prop("checked", false)
    }

    $(".conditions-switch").on("click", function() {
      if (!$(this).is(":checked")) {
        $("#conditions, #condition-plus-button").hide();
        // remove all bonus inputs
        $(".condition_content").each(function() {
          $(this).remove();
        });
      }else{
        $("#conditions, #condition-plus-button").show();
        if ($(".condition-input").length < 1) {
          $("#condition-plus-button").trigger("click");
        }
      }
    }).trigger("click");

    $("input[name^='conditions']").each(function() {
      conditions_values.push($(this).val());
    });

    // ------ publications types switches ------
    if ($("input[name='advertising_campaign[nb_posts_asked]']").val() == 0 || $("input[name='advertising_campaign[nb_posts_asked]']").val() == "" || $("input[name='advertising_campaign[nb_posts_asked]']").val() == null) {
      $(".publications-switch").prop("checked", true)
    }else{
      $(".publications-switch").prop("checked", false)
    }

    if ($("input[name='advertising_campaign[nb_reels_asked]']").val() == 0 || $("input[name='advertising_campaign[nb_reels_asked]']").val() == "" || $("input[name='advertising_campaign[nb_reels_asked]']").val() == null) {
      $(".reels-switch").prop("checked", true)
    }else{
      $(".reels-switch").prop("checked", false)
    }

    if ($("input[name='advertising_campaign[nb_stories_asked]']").val() == 0 || $("input[name='advertising_campaign[nb_stories_asked]']").val() == "" || $("input[name='advertising_campaign[nb_stories_asked]']").val() == null) {
      $(".stories-switch").prop("checked", true)
    }else{
      $(".stories-switch").prop("checked", false)
    }

    self.handleSwitchClick($(".publications-switch"), $(".publications-part"), $("input[name='advertising_campaign[nb_posts_asked]']"));
    self.handleSwitchClick($(".reels-switch"), $(".reels-part"), $("input[name='advertising_campaign[nb_reels_asked]']"));
    self.handleSwitchClick($(".stories-switch"), $(".stories-part"), $("input[name='advertising_campaign[nb_stories_asked]']"));

    // ------ slider ------
    let minfollowed = $("#minfollowed").prop('selectedIndex');
    let maxfollowed = $("#maxfollowed").prop('selectedIndex');
    if (typeof jQuery.ui !== 'undefined'){
      $( "#slider" ).slider({
        range: true,
        min: 0,
        max: 5,
        values: [minfollowed, maxfollowed+1],
        step: 1,
        slide: function( event, ui ) {
          if(ui.values[ 0 ] >= ui.values[ 1 ]){
            return false;
          }
          $("#minfollowed").prop('selectedIndex', ui.values[ 0 ]).change();
          $("#maxfollowed").prop('selectedIndex', ui.values[ 1 ]-1).change();
          
        }
      });
    }
    
    self.disable_wrong_options($("#maxfollowed"));
    self.disable_wrong_options($("#minfollowed"));
    self.disable_wrong_options($("#maxfollow"));
    self.disable_wrong_options($("#minfollow"));

    // select2 for categories

    $(".categories-select").select2({
      placeholder: "Choisir une catégorie",
      multiple: true,
      allowClear: true
    });
  }

  updateBonusLabelsAndName() {
    $(".bonus-label").each(function(index) {
      $(this).text("Contrepartie " + (index + 1));
    });

    $(".bonus-input").each(function(index) {
      $(this).attr("name", "bonus[bonus_" + (index + 1) + "]");
    });
  }

  addBonusInput(value) {
    var i = $(".bonus-input").length;
    i++;

    var html = `
    <div class="row bonus_content mt-1">
      <div class="col-12 col-lg-2">
        <div class="d-flex h-100 justify-content-start align-items-center">
          <label class="bonus-label">Contrepartie ${i}</label>
        </div>
      </div>
      <div class="col-12 col-lg-10">
        <div class="d-flex"> 
          <input type="text" name="bonus[bonus_${i}]" class="form-control bonus-input rounded-0" value="${value || ''}" />
          <button type="button" class="btn btn-outline-warning btn-sm ml-1 remove-button">-</button>
        </div>
      </div>
    </div>
    `;
    $("#bonus").append(html);

    this.updateBonusLabelsAndName();
  }

  removeBonusInput(button) {
    $(button).closest(".bonus_content").remove();
    this.updateBonusLabelsAndName();
  }

  updateConditionLabelsAndName() {
    $(".condition-label").each(function(index) {
      $(this).text("Condition " + (index + 1));
    });
  }

  addConditionInput(value) {
    var i = $(".condition-input").length;
    i++;

    var html = `
    <div class="row condition_content mt-1">
      <div class="col-12 col-lg-2">
        <div class="d-flex h-100 justify-content-start align-items-center">
          <label class="condition-label">Condition ${i}</label>
        </div>
      </div>
      <div class="col-12 col-lg-10">
        <div class="d-flex">
          <input type="text" name="conditions[condition_${i}]" class="form-control condition-input rounded-0" value="${value || ''}" />
          <button type="button" class="btn btn-outline-warning btn-sm ml-1 condition-remove-button">-</button>
        </div>
      </div>
    </div>
    `;
    $("#conditions").append(html);

    this.updateConditionLabelsAndName();
  }

  removeConditionInput(button) {
    $(button).closest(".condition_content").remove();
    this.updateConditionLabelsAndName();
  }

  set_form_values(){
    $($("#minfollowed").find("option")[$("#slider").slider("values",0)]).prop('selected', true).change();
    $($("#maxfollowed").find("option")[$("#slider").slider("values",1)-1]).prop('selected', true).change();
  }

  set_slider_values(){
    $("#slider").slider("values",0, $("#minfollowed").prop('selectedIndex'));
    $("#slider").slider("values",1, ($("#maxfollowed").prop('selectedIndex')+1));
  }

  disable_wrong_options(btn){
    let val =  Number($(btn).val());
    if($(btn).hasClass("js-followed_parameter")){
      if($(btn).attr('id') == "minfollowed"){
          $('#maxfollowed option').each(function() {
            $(this).prop('disabled', Number($(this).val()) <= val)
        })
      }else{
          $('#minfollowed option').each(function() {
            $(this).prop('disabled', Number($(this).val()) >= val)
        })
      }
    }else{
      if($(btn).attr('id') == "minfollow"){
          $('#maxfollow option').each(function() {
          $(this).prop('disabled', Number($(this).val()) <= val)
        })
      }else{
        $('#minfollow option').each(function() {
        $(this).prop('disabled', Number($(this).val()) >= val)
        })
      }
    }
  }

 handleSwitchClick(switchElement, targetElement, inputElement) {
    switchElement.on("click", function() {
      if ($(this).is(":checked")) {
        targetElement.show();
      }else{
        targetElement.hide();
        inputElement.val(0);
      }
    }).trigger("click");
  } 

  onDataChanged(data) {
  }

  onDestroy() {
      this.dropifyPicture.data('dropify').destroy();
      this.dropifyLogo.data('dropify').destroy();
  }
}