export default class ListManager {

  constructor() {      
    this.bindEvents();
  }


  bindEvents() {
    var self = this;
    $(".add-to-list-btn").on('click', function(){
      self.addUserToList(this, self);
    });

    $(".remove-from-list-btn").on('click', function(){
        self.removeUserFromList(this, self);
    });
  }

  addUserToList(element, self){
    var list_id = $(element).data("list");
    var user_id = $(element).data("user");
    $.ajax({
        url: '/lists/' + list_id + '/add-user-to-list?user=' + user_id,
        method: 'PUT'
    })
    .done(function (response) {
        $(element).removeClass('add-to-list-btn').addClass('remove-from-list-btn');
        $(element).find('.mdi').removeClass('text-muted').addClass('text-primary');
        $(element).find('.text-dark').removeClass('text-dark').addClass('text-primary');
        $(element).find('.badge')
        .removeClass('badge-soft-info text-info')
        .addClass('badge-soft-primary text-primary');

        var badges = $(document).find(".list-" + list_id +"-nb-badge");
        for (var i = 0; i < badges.length; i++) {
            badges[i].innerHTML = parseInt(badges[i].innerText) + 1;
        }

        $(element).unbind();
        $(element).on('click', function (){
            self.removeUserFromList(this, self);
        });
    });
  }

  removeUserFromList(element, self){
      var list_id = $(element).data("list");
      var user_id = $(element).data("user");
      $.ajax({
          url: '/lists/' + list_id + '/remove-user-from-list?user=' + user_id,
          method: 'PUT'
      })
      .done(function (response) {
          $(element).removeClass('remove-from-list-btn').addClass('add-to-list-btn');
          $(element).find('.mdi').removeClass('text-primary').addClass('text-muted');
          $(element).find('.text-primary').removeClass('text-primary').addClass('text-dark');
          $(element).find('.badge')
          .removeClass('badge-soft-primary text-primary text-dark')
          .addClass('badge-soft-info text-info');

          var badges = $(document).find(".list-" + list_id +"-nb-badge");
          for (var i = 0; i < badges.length; i++) {
              badges[i].innerHTML = parseInt(badges[i].innerText) - 1;
          }

          $(element).unbind();
          $(element).on('click', function (){
              self.addUserToList(this, self);
          });
      });
  }

  onDataChanged(data) {
  }

  onDestroy() {
    
  }

}