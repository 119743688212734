export default class ListsShow {

    constructor() {
      if (this.dataTable != null) {
        this.dataTable.destroy();
      }
      this.bindEvents();
      //window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
        var self = this;
        this.dataTable = $('#list-table').DataTable({
            "info": false,
            "pageLength": 5,
            "lengthChange": false,
            "searching": true,
            "fixedColumns": true,
            "language": {
                url: '/assets/data-tables/locales/' + I18n.locale + '.json',
                previous: "<i class='mdi mdi-chevron-left'>",
                next: "<i class='mdi mdi-chevron-right'>"
            },
            drawCallback: function () {
                $(".dataTables_paginate > .pagination").addClass("pagination-rounded")
            },
            dom: 'Bfrtip',
            "ordering": true,
            "aoColumnDefs": [
                { "bSortable": false, "aTargets": [ 0, 1, 8, 9, 10 ] }
              ],
            buttons: [
                
            ]
        });

        $('.add-to-list-btn').on("click", function(){
            self.addusersToList(this.getAttribute('data-list'), this)
        });

        $(".showmore, .showless").on("click", function () {
            self.display_lists();
        });

        $(".edit-comment-btn").on("click", function () {
            this.parentElement.children[0].classList.add("d-none");
            this.parentElement.children[1].classList.remove("d-none");
            this.classList.add("d-none");
        });

        $("#delete-from-list-btn").on("click", function(){
            if($('.checkbox:checkbox:checked').length > 0){
                $('#confirmation-modal').modal('show');
                var usernames = []
                for(var i = 0; i < $('.checkbox:checkbox:checked').length; i++){
                    usernames.push($('.checkbox:checkbox:checked')[i].parentElement.parentElement.getAttribute('data-username'));
                }                
            }
        });

        this.dataTable.on("draw", function(){
          $("#list-table_filter").addClass("d-flex")
         $(".list-table-btn-group").appendTo($("#list-table_filter"))
        });

        $("#remove-from-list").on("click", function(){
            console.log("clicked");
            self.removeUsersFromList(this.getAttribute('data-list'));
        });

        $(".confirmation-modal").on('hidden.bs.modal', function () {
            this.querySelectorAll('.modal-body')[0].children[0].children[0].innerHTML = "";
        });

        $('.modal').on('hidden.bs.modal', function () {
            $('.modal .text-primary').each(function(i){
                this.classList.remove("text-primary");
                if ($(this).is("p")){
                    this.classList.add("text-dark");
                }else if ($(this).is("i")){
                    this.classList.add("text-muted");
                }else if ($(this).is("span")){
                    this.classList.remove("badge-soft-primary");
                    this.classList.add("badge-soft-info");
                    this.classList.add("text-info");
                }else{
                    this.classList.add("text-info");
                }
            });
        });

        $(".checkbox").on("click", function(){
            self.display_toolbar();
            if($('.checkbox:checkbox:checked').length < 5){
                self.deselect_main_checkboxe();
            }
        });

        $(".main-checkbox").on("click", function(){
            
            if ($(this).is(':checked')){
                document.getElementById("toolbar").style.display = "block";
            }else{
                document.getElementById("toolbar").style.display = "none";
            }

            self.checkboxes_change_status(true);
        });

        $(document).on('turbolinks:load', function(){
            if ($('.checkbox:checkbox:checked').length > 0){
                self.display_toolbar();
            }
        });
    }

    addusersToList(list_id, element){
        
        var elements_checked = $('.checkbox:checkbox:checked');
        var usernames = []
        for(var i = 0; i < elements_checked.length; i++){
            usernames.push(elements_checked[i].parentElement.parentElement.getAttribute('data-username'));
        }
        
        var url = "/lists/" + list_id + "/add-multiple-users-to-list?users=" + usernames
        
        $.ajax({
            url: url,
            method: "PUT",
            dataType: "json"
        })
        .done(function (response) {
            $(element).find('.text-dark').removeClass("text-dark").addClass('text-primary').find("text-muted").removeClass("text-muted").addClass("text-primary");
            $(element).find(".mdi").removeClass("text-muted").addClass("text-primary");
            $(element).find(".badge").removeClass("badge-soft-info text-info").addClass("badge-soft-primary text-primary").html(parseInt($(element).find(".badge").text()) + usernames.length - response.skipped);
            
            var edited_list_class = ".nb_for_" + list_id
            $(edited_list_class).each(function() {
                $(this).html(parseInt($(this).text()) + usernames.length - response.skipped);
            });
        });
    }

    removeUsersFromList(list_id){
        var elements_checked = $('.checkbox:checkbox:checked');
        var usernames = []
        for(var i = 0; i < elements_checked.length; i++){
            usernames.push(elements_checked[i].parentElement.parentElement.getAttribute('data-username'));
        }
   
        var url = "/lists/" + list_id + "/remove-multiple-users-from-list?users=" + usernames
        
        console.log(url)
        $.ajax({
            url: url,
            method: "PUT"
        })
        .done(function (response) {
            for(var i = 0; i < elements_checked.length; i++){
                elements_checked[i].parentElement.parentElement.remove();
            }

            var edited_list_class = ".nb_for_" + list_id
            $(edited_list_class).each(function() {
                $(this).html(parseInt($(this).text()) - usernames.length);
            });           
        });
    }

    onDataChanged(data) {
    }

    onDestroy() {
      if (this.dataTable){
        this.dataTable.destroy();
        this.dataTable = null;
      }
    }

    checkboxes_change_status(all=null){
        if (all){
            if (document.getElementsByClassName("main-checkbox")[0].checked){
                var checkboxes = document.getElementsByClassName("checkbox");
                for (var i=0; i < checkboxes.length; i++){
                    checkboxes[i].checked = true;
                }
            }
            else if (!document.getElementsByClassName("main-checkbox")[0].checked){
                var checkboxes = document.getElementsByClassName("checkbox");
                for (var i=0; i < checkboxes.length; i++){
                    checkboxes[i].checked = false;
                }
                document.getElementById("toolbar").style.display = "none";
            }
        }
    }

    deselect_main_checkboxe(){
        document.getElementsByClassName("main-checkbox")[0].checked = false;
    }

    display_toolbar(){
        var toolbar = document.getElementById("toolbar");
        toolbar.style.display = "none";
        var nb_cb_checked = 0;

        if (document.getElementsByClassName("main-checkbox")[0].checked){
            toolbar.style.display = "block";
        }

        var checkboxes = document.getElementsByClassName("checkbox");
        for (var i=0; i < checkboxes.length; i++){
            if (checkboxes[i].checked == true){
                nb_cb_checked++;
            }
        }

        if(nb_cb_checked > 0){
            toolbar.style.display = "block";
        }else{
            toolbar.style.display = "none";
        }
    }

    display_lists(){
        var div = $("#more-lists");
        
        if (div.css("display") == "none"){
            div.css({"display":"block"});
        }else{
            div.css({"display":"none"});
        }

        var showmore_elements_labels = $(".showmore");
        var showless_elements_labels = $(".showless");

        if (showmore_elements_labels.css("display") == "block"){
            showmore_elements_labels.css({"display":"none"});
            showless_elements_labels.css({"display":"block"});
        }else{
            showmore_elements_labels.css({"display":"block"});
            showless_elements_labels.css({"display":"none"});
        }        
    }

}