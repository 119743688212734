export default class CandidaciesIndex {
  constructor() {
    this.bindEvents();
  }

  bindEvents() {
    self = this;

    var page_length = 10;

    this.dataTable = $('#candidacies-datatable').DataTable({
      "info": false,
      "lengthChange": false,
      "pageLength": page_length,
      "searching": false,
      "order": [[0, "desc"]],
      "language": {
          url: '/assets/data-tables/locales/' + I18n.locale + '.json',
          previous: "<i class='mdi mdi-chevron-left'>",
          next: "<i class='mdi mdi-chevron-right'>"
      },
      drawCallback: function () {
          $(".dataTables_paginate > .pagination").addClass("pagination-rounded js-save-order")
      },
      dom: 'Bfrtip',
      buttons: [
        
        ],
        "aoColumnDefs": [
          { "bSortable": false, "aTargets": [ 0, 10 ] }
        ],
    });

    $(".checkbox").on("click", function() {
      if ($(".checkbox:checkbox:checked").length > 0) {
        $("#check-candidacies-btn").removeClass("d-none").addClass("d-block");
      } else {
        $("#check-candidacies-btn").addClass("d-none").removeClass("d-block");
      }

      if($('.checkbox:checkbox:checked').length < page_length){
        document.getElementsByClassName("main-checkbox")[0].checked = false;
      }
    });

    $("#check-candidacies-btn").on("click", function() {
      for (var i = 0; i < $(".checkbox:checkbox:checked").length; i++) {
        if ($(".checkbox:checkbox:checked")[i].parentElement.parentElement.getAttribute("data-monitored") == "false") {
          self.checkCandidacy(($(".checkbox:checkbox:checked")[i].parentElement.parentElement.getAttribute("data-username")));
        } 
      }
    });

    $( document ).ready(function() {
      for (var i = 0; i < $(".checkbox").length; i++) {
        $(".checkbox")[i].checked = false;
        $(".main-checkbox")[0].checked = false;
      }
    });

    $(".main-checkbox").on("click", function() {
      if (this.checked) {
        for (var i = 0; i < $(".checkbox").length; i++) {
          $(".checkbox")[i].checked = true;
        }
        $("#check-candidacies-btn").removeClass("d-none").addClass("d-block");
      } else {
        for (var i = 0; i < $(".checkbox").length; i++) {
          $(".checkbox")[i].checked = false;
        }
        $("#check-candidacies-btn").addClass("d-none").removeClass("d-block");
      }
    });
  }

  onDestroy() {
    this.dataTable.destroy();
    this.dataTable = null;
  }

  checkCandidacy(username) {
    username = "@" + username;
    $.ajax({
      url: "/stalk",
      type: "POST",
      data: {
        is_candidacy: true,
        user_to_stalk: username
      }
    });
  }
}