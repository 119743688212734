export default class ConfirmationModal {

    constructor() {
    }

    displayConfirm(title, message, opts) {
        let $confirm_modal = $("#confirm_modal");
        $confirm_modal.find(".modal-title").html(title);
        $confirm_modal.find(".modal-body").html(message);

        var modalType = (opts!=null && opts.modal_type!=null) ? opts.modal_type : "normal";
        if(modalType==="large") {
            $confirm_modal.find(".modal-dialog").addClass("modal-lg");
        }
        else {
            $confirm_modal.find(".modal-dialog").removeClass("modal-lg");
        }


        if(opts.positive_btn!=null) {
            var type = opts.positive_btn.type;
            if(type==null) {
                type = "primary";
            }
            $confirm_modal.find(".positive-btn").attr("class", "btn waves-effect waves-light positive-btn btn-"+type).html(opts.positive_btn.label).off("click").on("click", function(e) {
                if(opts.positive_btn.callback!=null) {
                    opts.positive_btn.callback();
                }
                $confirm_modal.modal("hide");

            }).show();
        }
        else {
            $confirm_modal.find(".positive-btn").off("click").hide();
        }

        if(opts.negative_btn!=null) {
            $confirm_modal.find(".negative-btn").html(opts.negative_btn.label).off("click").on("click", function(e) {
                if(opts.negative_btn.callback!=null) {
                    opts.negative_btn.callback();
                }
                $confirm_modal.modal("hide");
            }).show();
        }
        else {
            $confirm_modal.find(".negative-btn").off("click").hide();
        }

        $confirm_modal.modal({ backdrop: "static"});
    }
}