import AdminsForm from "../utils/AdminsForm";

export default class AdminsEdit {

    constructor() {
        this.adminsForm = new AdminsForm();
        this.bindEvents();
        //window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
    }

    onDataChanged(data) {
    }

    onDestroy() {
        this.adminsForm.onDestroy();
    }
}