import AdvertisingAccountForm from "../utils/AdvertisingAccountForm";

export default class AdvertisingAccountEdit {

    constructor() {
        this.accountForm = new AdvertisingAccountForm();
        this.bindEvents();
        //window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
    }

    onDataChanged(data) {
    }

    onDestroy() {
        this.accountForm.onDestroy();
    }
}