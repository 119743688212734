import ApexCharts from 'apexcharts'

export default class InstagramAccountsBotTracker {

    constructor() {
        this.bindEvents();
    }


    bindEvents() {
        this.dataTable = $('#log-table').DataTable({
            "info": false,
            order: [[1, "desc"]],
            "lengthChange": false,
            "paging": false,
        });

        if (window.localStorage.bot_tracker_order !== undefined) {
            let sort = window.localStorage.bot_tracker_order.split(",")
            this.dataTable.order([sort[0], sort[1]])
        }
        if (window.localStorage.bot_tracker_search !== undefined) {
            this.dataTable.search(window.localStorage.bot_tracker_search)
        }
        this.dataTable.draw()

        var data_users = $("#analysis-chart-users").data("users-fail-count")
        var labels_users = $("#analysis-chart-users").data("labels")
        labels_users = labels_users.map(function(username) {
          return '@' + username;
        });

        var data_hashtags = $("#analysis-chart-hashtags").data("hashtags-fail-count")
        var labels_hashtags = $("#analysis-chart-hashtags").data("labels")
        labels_hashtags = labels_hashtags.map(function(hashtag) {
          return '#' + hashtag;
        });

        this.user_chart = this.analysisChart("#analysis-chart-users", data_users, labels_users)
        this.user_chart.render()

        this.hashtag_chart = this.analysisChart("#analysis-chart-hashtags", data_hashtags, labels_hashtags)
        this.hashtag_chart.render()

        $(".js-save-params").click(this.saveParams)
    }

    saveParams() {
        window.localStorage.bot_tracker_order = this.dataTable.order().join()
        window.localStorage.bot_tracker_search = this.dataTable.search()
    }

    changePageId(url, operation) {
        let page = url.searchParams.get("page")
        let cur_page = page === null ? 1 : parseInt(page)
        cur_page = operation === "+" ? cur_page + 1 : cur_page - 1
        cur_page = cur_page.toString()
        if (page === null) {
            window.location.href = window.location.href + "&page=" + cur_page
        } else {
            let url_string = window.location.href
            let start_index = url_string.indexOf("page=") + 5
            let end_index = url_string.slice(start_index).indexOf("&")
            window.location.href = url_string.slice(0, start_index) + cur_page
            if (end_index !== -1) {
                window.location.href += url_string.slice(end_index)
            }
        }
    }

    analysisChart(id, data, labels) {
      var options = {
        series: [{
        name: "Nombre d'échecs",
        data: data
      }],
        chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      colors: ['#ff7b7b'],
      xaxis: {
        categories: labels,
        title: {
          text: "Nombre d'échecs"
        }
      },
      yaxis: {
        title: {
          text: undefined
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + " échecs"
          }
        }
      }
      };

      var chart = new ApexCharts(document.querySelector(id), options);
      return chart
    }

    onDataChanged(data) {
    }

    onDestroy() {
        this.dataTable.destroy();
        this.dataTable = null;
        this.chart.destroy();
    }

}
