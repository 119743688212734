import ConfigRoutes from "../../config/Routes"
import DashboardIndex from "../controllers/DashboardIndex";
import PermissionsIndex from "../controllers/PermissionsIndex";
import AdminsIndex from "../controllers/AdminsIndex";
import AdminsEdit from "../controllers/AdminsEdit";
import AdminsNew from "../controllers/AdminsNew";
import RoleIndex from "../controllers/RoleIndex";
import RoleEdit from "../controllers/RoleEdit";
import ProfileIndex from "../controllers/ProfileIndex";
import HashtagForm from "../controllers/HashtagForm";
import HashtagShow from "../controllers/HashtagShow";
import HashtagPlaces from "../controllers/HashtagPlaces";
import UsersShow from "../controllers/UsersShow";
import HashtagPosts from "../controllers/HashtagPosts";
import UsersPosts from "../controllers/UsersPosts";
import AlertForm from "../controllers/AlertForm";
import UsersStatsHistoryTable from "../controllers/UsersStatsHistoryTable";
import Anomalies from "../controllers/Anomalies";
import FollowsHistoryTable from "../controllers/FollowsHistoryTable";
import FollowsHistorySearch from "../controllers/FollowsHistorySearch";
import ListsShow from "../controllers/ListsShow";
import UsersIndex from "../controllers/UsersIndex";
import InstagramAccountsIndex from "../controllers/InstagramAccountsIndex";
import CategoriesIndex from "../controllers/CategoriesIndex";
import InstagramAccountsBotTracker from "../controllers/InstagramAccountsBotTracker";
import InstagramAccountsShow from "../controllers/InstagramAccountsShow";
import UserAgentIndex from "../controllers/UserAgentIndex";
import HashtagsHandlerIndex from "../controllers/HashtagsHandlerIndex";
import ProxiesIndex from "../controllers/ProxiesIndex";
import SmsObjectsIndex from "../controllers/SmsObjectsIndex";
import CandidaciesIndex from "../controllers/CandidaciesIndex";
import AdvertisingAccountNew from "../controllers/AdvertisingAccountNew";
import AdvertisingAccountEdit from "../controllers/AdvertisingAccountEdit";
import AdvertisingCampaignEdit from "../controllers/AdvertisingCampaignEdit";
import AdvertisingCampaignNew from "../controllers/AdvertisingCampaignNew";
import AdvertisingCampaignIndex from "../controllers/AdvertisingCampaignIndex";
import HashtagEdit from "../controllers/HashtagEdit";
import HashtagSelectionInfluencers from "../controllers/HashtagSelectionInfluencers";
import BackofficeAdministrationAdvertisingCampaignsParticipants from "../controllers/BackofficeAdministrationAdvertisingCampaignsParticipants";
import BackofficeAdministrationAdvertisingCampaignsParticipantLinks from "../controllers/BackofficeAdministrationAdvertisingCampaignsParticipantLinks";
import InfluencersCampaignDetails from "../../influencers/controllers/InfluencersCampaignDetails"


export default class Routes extends ConfigRoutes {

    static routes = {
        "BackofficeSmsObjectsIndex": SmsObjectsIndex,
        "BackofficeProxiesIndex": ProxiesIndex,
        "BackofficeHashtagsHandlerIndex": HashtagsHandlerIndex,
        "BackofficeUserAgentsIndex": UserAgentIndex,
        "BackofficeCategoriesIndex": CategoriesIndex,
        "BackofficeDashboardIndex": DashboardIndex,
        "BackofficePermissionsIndex": PermissionsIndex,
        "BackofficeAdminsIndex": AdminsIndex,
        "BackofficeAdminsEdit": AdminsEdit,
        "BackofficeAdminsUpdate": AdminsEdit,
        "BackofficeAdminsNew": AdminsNew,
        "BackofficeAdminsCreate": AdminsNew,
        "BackofficeRolesIndex": RoleIndex,
        "BackofficeRolesEdit": RoleEdit,
        "BackofficeRolesNew": RoleEdit,
        "BackofficeRolesUpdate": RoleEdit,
        "BackofficeRolesCreate": RoleEdit,
        "BackofficeProfileIndex": ProfileIndex,
        "BackofficeProfileNewAccess": ProfileIndex,
        "BackofficeHashtagsNew": HashtagForm,
        "BackofficeHashtagsEdit": HashtagForm,
        "BackofficeHashtagsShow": HashtagShow,
        "BackofficeHashtagsAllPosts": HashtagPosts,
        "BackofficeHashtagsAllPostsInfluencers": HashtagPosts,
        "BackofficeHashtagsSelection": HashtagPosts,
        "BackofficeHashtagsSelectionInfluencers": HashtagPosts,
        "BackofficeHashtagsShowPlace": HashtagPosts,
        "BackofficeHashtagsPlaces": HashtagPlaces,
        "BackofficeUsersShow": UsersShow,
        "BackofficeUsersPlaces": HashtagPlaces,
        "BackofficeUsersPosts": UsersPosts,
        "BackofficeUsersStories": UsersPosts,
        "BackofficeUsersStatsHistory": UsersStatsHistoryTable,
        "BackofficeUsersAnomalies": Anomalies,
        "BackofficeFollowsHistoryIndex": FollowsHistoryTable,
        "BackofficeFollowsHistoryIndex": FollowsHistorySearch,
        "BackofficeFollowsHistoryUsers": FollowsHistorySearch,
        "BackofficeFollowsHistoryHashtags": FollowsHistorySearch,
        "BackofficeFollowsHistoryShowEntity": FollowsHistoryTable,
        "BackofficeAlertsNew": AlertForm,
        "BackofficeAlertsEdit": AlertForm,
        "BackofficeAlertsCreate": AlertForm,
        "BackofficeAlertsUpdate": AlertForm,
        "BackofficeAlertsAlertForm": AlertForm,
        "BackofficeAdministrationInstagramAccountsIndex": InstagramAccountsIndex,
        "BackofficeAdministrationInstagramAccountsShow": InstagramAccountsShow,
        "BackofficeAdministrationInstagramAccountsBotTracker": InstagramAccountsBotTracker,
        "BackofficeAdministrationEntitiesNewAccess": ProfileIndex,
        "BackofficeListsShow": ListsShow,
        "BackofficeUsersIndex": UsersIndex,
        "BackofficeCandidaciesIndex": CandidaciesIndex,
        "BackofficeAdministrationAccountsNew": AdvertisingAccountNew,
        "BackofficeAdministrationAccountsEdit": AdvertisingAccountEdit,
        "BackofficeAdministrationAdvertisingCampaignsNew": AdvertisingCampaignNew,
        "BackofficeAdministrationAdvertisingCampaignsCreate": AdvertisingCampaignNew,
        "BackofficeAdministrationAdvertisingCampaignsEdit": AdvertisingCampaignEdit,
        "BackofficeAdministrationAdvertisingCampaignsUpdate": AdvertisingCampaignEdit,
        "BackofficeAdministrationAdvertisingCampaignsIndex": AdvertisingCampaignIndex,
        "BackofficeHashtagsEdit": HashtagEdit,
        "BackofficeHashtagSelectionInfluencers": HashtagSelectionInfluencers,
        "BackofficeAdministrationAdvertisingCampaignsParticipants": BackofficeAdministrationAdvertisingCampaignsParticipants,
        "BackofficeAdministrationAdvertisingCampaignsParticipantLinks": BackofficeAdministrationAdvertisingCampaignsParticipantLinks,
        "BackofficeAdministrationAdvertisingCampaignsShow": InfluencersCampaignDetails
    };

    constructor() {
        super();
        this.routes = Object.assign(this.routes, Routes.routes);
    }
}
