
export default class InstagramAccountsIndex {

    constructor() {
        this.bindEvents();
        this.interval = null;
    }

    bindEvents() {

        let self = this;

        $('.js-load-2fa-modal-content').click(function(){
            let id = $(this).data("id");
            self.load2faModalContent(id);
            $("#btn_toggle_2fa_modal_"+id).prop('disabled', true);
        })

        $('#get_2fa_code_modal').on('hidden.bs.modal', function (event) {
            clearInterval(self.interval);
        })

        $('.js-load-bot-activity-modal').click(this.loadBotActivityModal)

        $('.js-recuperation-code-modal').click(this.loadRecuperationCodeModal)

        $('.js-add-proxy-modal').click(this.loadAddProxyModal)

    }

    appendParamsInUrl(url, params, object) {
        let value = $(object).data(params);

        if (value === undefined || value === "") {
            return url
        }
        return url + "&" + params + "=" + value
    }

    loadBotActivityModal() {
        let url = "/administration/instagram_accounts/" + $(this).data("id") + "/load_bot_activity_modal?redirect=show"
        let params_array = ["lock", "state", "action_type", "object_type", "result"]

        for (let i = 0; i < params_array.length; i++) {
            url = application.current_handler.appendParamsInUrl(url, params_array[i], this);
        }

        let ajaxData = {
            url: url,
            method: "GET"
        };

        $.ajax(ajaxData).done(function(html) {
            $('.bot_activity_container').empty().append(html);
            $('#bot_activity_modal').modal("show");
        })
    }

    loadRecuperationCodeModal() {
        let id = $(this).data("id");
        let redirect = $(this).data("redirect")
        let ajaxData = {
            url: "/administration/instagram_accounts/" + id + "/load_recuperation_code" + "?redirect=" + redirect,
            method: "GET"
        };

        $.ajax(ajaxData).done(function(html) {
            $('#recuperation_code_modal_container').empty().append(html);
            $('#recuperation_code_modal').modal("show");
        })
    }

    loadAddProxyModal() {
      let id = $(this).data("id");
      let ajaxData = {
        url: "/administration/instagram_accounts/" + id + "/load_proxy_modal",
        method: "GET"
      }

      $.ajax(ajaxData).done(function(html) {
          $('#add_proxy_modal_content').empty().append(html);
          $('#add_proxy_modal_content select.select2-with-search').select2({
              placeholder: $('#add_proxy_modal_content select.select2-with-search').data("placeholder"),
              minimumResultsForSearch: 5
          });
          $('#add_proxy_modal').modal("show");
      })
    }

    load2faModalContent(id) {
      let self = this;
        let ajaxData = {
            url: "/administration/instagram_accounts/" + id + "/load_2fa_modal",
            method: "GET"
        };

        $.ajax(ajaxData).done(function(html) {
            $('#2fa_modal_container').empty().append(html);
            $('#get_2fa_code_modal').modal("show");
            // $("#btn_toggle_2fa_modal_"+id).tooltip("hide");
            $("#btn_toggle_2fa_modal_"+id).prop('disabled', false);
            let maxTime = 30
            var dt = new Date();
            var seconds = dt.getSeconds();
            var timeLeft = seconds%30;
            timeLeft = 30 - timeLeft;
            $("#2fa_time_left").text(""+timeLeft);


            self.interval = setInterval(function() { //fonction pour compteur
                if (timeLeft > 0 ){
                  timeLeft--;
                }
                // Display 'counter' wherever you want to display it.
                $("#2fa_time_left").text(""+timeLeft);
                if (timeLeft == 0) {
                  console.log("timeleft 0")
                    clearInterval(self.interval);
                    application.current_handler.load2faModalContent(id);
                }
            }, 1000);
        })

    }
}
