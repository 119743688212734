export default class VideoModalManager {

  constructor() {      
    this.bindEvents();
  }

  bindEvents() {
    $(".media-modal").on("hidden.bs.modal", function(e) {
      $(".video-player").each(function() {
        $(this).get(0).pause();
      });
    });
  }

  onDataChanged(data) {
  }

  onDestroy() {
    
  }

}