export default class UsersStatsHistoryTable {

    constructor() {
        this.bindEvents();
        //window.application.setOnDataChangeListener(this);
    }


    bindEvents() {

        var username = document.getElementById("stats-datatable").getAttribute("data-username")

        this.dataTable = $('#stats-datatable').DataTable({
            "info": false,
            "lengthChange": false,
            "searching": false,
            "order": [[0, "desc"]],
            "language": {
                url: '/assets/data-tables/locales/' + I18n.locale + '.json',
                previous: "<i class='mdi mdi-chevron-left'>",
                next: "<i class='mdi mdi-chevron-right'>"
            },
            drawCallback: function () {
                $(".dataTables_paginate > .pagination").addClass("pagination-rounded js-save-order")
            },
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'csvHtml5',
                    className: "btn btn-outline-secondary pill btn-xs btn-1",
                    text: "export csv",
                    title: username,
                    init: function(api, node, config) {
                        $(node).removeClass('btn-secondary')
                    }
                }
            ]
        });

        this.dataTable.on('draw', function () {
          $(".btn-1").appendTo($(".export-btn-1"));
        });

        if (window.localStorage.userStatHistoryOrder !== undefined) {
            let order = window.localStorage.userStatHistoryOrder.split(",")
            this.dataTable.order(order[1], order[2])
        }

        $("js-save-order").click(this.saveOrder)
    }

    saveOrder() {
        window.localStorage.userStatHistoryOrder = application.current_handler.DataTable.order().join()
    }

    onDataChanged(data) {
    }

    onDestroy() {
        this.dataTable.destroy();
        this.dataTable = null;
    }
}