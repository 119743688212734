export default class BackofficeAdministrationAdvertisingCampaignsParticipants {

  constructor() {
      this.bindEvents();
  }


  bindEvents() {
    let self = this;

    this.dataTable = $('#basic-datatable').DataTable({
      "info": false,
      "lengthChange": false,
      "searching": false,
      "language": {
        url: '/assets/data-tables/locales/' + I18n.locale + '.json',
        previous: "<i class='mdi mdi-chevron-left'>",
        next: "<i class='mdi mdi-chevron-right'>"
      },
      drawCallback: function () {
         $(".dataTables_paginate > .pagination").addClass("pagination-rounded")
      },
      dom: 'Bfrtip',
      "ordering": true,
      // remove csv export button
      buttons: [],
      "columnDefs": [
        {
          "targets": [ 1,8],
          "orderable": false,
        }],
      initComplete: function () {
        $('#custom-search-input').on('keyup', function () {
            self.dataTable.search($(this).val()).draw();
        });
      }
    });
  }

  onDataChanged(data) {
  }

  onDestroy() {
    if (this.dataTable){
      this.dataTable.destroy();
      this.dataTable = null;
    }
  }
}
