import AdvertisingCampaignForm from "../utils/AdvertisingCampaignForm";

export default class AdvertisingAccountNew {

    constructor() {
        this.campaignForm = new AdvertisingCampaignForm();
        this.bindEvents();
        //window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
      console.log("bindEvents");
    }

    onDataChanged(data) {
    }

    onDestroy() {
        this.campaignForm.onDestroy();
    }
}