export default class FollowsHistoryTable {

    constructor() {
        this.bindEvents();
        //window.application.setOnDataChangeListener(this);
    }


    bindEvents() {

        this.dataTable = $('table.display').DataTable({
            "info": false,
            "paging": false,
            "lengthChange": false,
            "ordering": false,
        });

    }

    onDataChanged(data) {
    }

    onDestroy() {
        this.dataTable.destroy();
        this.dataTable = null;
    }

}