import VideoModalManager from "../utils/VideoModalManager";

export default class UsersPosts {
    
    constructor() {
        const videoModalManager = new VideoModalManager();
        this.bindEvents();

    }

    bindEvents() {
        $("#related_hashtag_selection").on("change", function(e) {
          Turbolinks.visit($(this).find("option:selected").data("url"));
        });

        $("#alert_selection").on("change", function(e) {
          Turbolinks.visit($(this).find("option:selected").data("url"));
        });
    }
}