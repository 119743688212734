import ListManager from '../utils/ListManager';

export default class UsersIndex {

    constructor() {
        const listManager = new ListManager();
        this.bindEvents();
    }


    bindEvents() {
        var self = this

        let params = (new URL(document.location)).searchParams;
        let display = params.get("display");

        if(display == "list") {
            this.dataTable = $('#basic-datatable').DataTable({
                "info": false,
                "lengthChange": false,
                "searching": false,
                "aaSorting": [],
                "language": {
                    url: '/assets/data-tables/locales/' + I18n.locale + '.json',
                    previous: "<i class='mdi mdi-chevron-left'>",
                    next: "<i class='mdi mdi-chevron-right'>"
                },
                drawCallback: function () {
                    $(".dataTables_paginate > .pagination").addClass("pagination-rounded")
                },
                dom: 'Bfrtip',
                "ordering": true,
                // remove csv export button
                buttons: [],
                "columnDefs": [
                    {
                        "targets": [ 1,9],
                        "orderable": false,
                    }]
            });
        }
    }

    onDataChanged(data) {
    }

    onDestroy() {
      if (this.dataTable){
        this.dataTable.destroy();
        this.dataTable = null;
      }
    }
}
