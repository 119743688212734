export default class FollowHistorySearch {

    constructor() {
        this.bindEvents();
    }


    bindEvents() {
        $(".form-control").keyup(this.search)
    }

    search() {
        let used_class = $(this).data("class");
        console.log(used_class, this.value);
        $.ajax({
            url: "/follows_history/search_item?search=" + this.value + "&class=" + used_class,
            method: 'GET'
        })
        .done(function (response) {
            $(".table-body").empty().append(response);
        });
    }

    onDataChanged(data) {
    }

    onDestroy() {
    }

}