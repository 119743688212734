export default class HashtagsIndex {

    constructor() {
        this.bindEvents();
    }


    bindEvents() {
      let self = this;
      $(".js-edit-hashtag-posts").click(function(e){
        self.loadUpdateModal(this);
      })

      this.dataTable = $('#handler-datatable').DataTable({
        "info": false,
        "lengthChange": false,
        "searching": true,
        "language": {
            url: '/assets/data-tables/locales/' + I18n.locale + '.json',
            previous: "<i class='mdi mdi-chevron-left'>",
            next: "<i class='mdi mdi-chevron-right'>"
        },
        drawCallback: function () {
            $(".dataTables_paginate > .pagination").addClass("pagination-rounded")
        },
        dom: 'Bfrtip',
        "ordering": true,
        // remove csv export button
        buttons: [],
        "columnDefs": [
            {
                "targets": [ 1,9],
                "orderable": false,
            }]
      });
    }
    loadUpdateModal(btn) {
        let self = this;
        let id = $(btn).data("id");
        let ajaxData = {
            url: "/hashtags_handler/" + id + "/load_update_posts_count",
            method: "GET"
        };

        $.ajax(ajaxData).done(function(html) {
            $('#update_hashtag_posts_count').empty().append(html);
            $('#posts_count_modal').modal("show");
            self.bindAjaxEvent();
        })
    }

    bindAjaxEvent(){

      $("#posts_count_range").on("input", function(){
        let posts_to_check = $("#posts_count_range").val();
        $("#posts_count_value").html(""+posts_to_check);
      })


      $("#posts_count_confirm").unbind("click").click(function() {
        let posts_to_check = $("#posts_count_range").val();
        let link = $("#posts_count_confirm").data("href");
        $("#posts_count_confirm").attr("href", link.replace("__POST_VALUE__", posts_to_check));
      })

    }

}
