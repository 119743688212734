import ApexCharts from 'apexcharts'
import { post } from 'jquery';
import { marker } from 'leaflet';
import Swiper, { Navigation } from 'swiper';
import ListManager from "../utils/ListManager";
import VideoModalManager from "../utils/VideoModalManager";
Swiper.use([Navigation]);

export default class UsersShow {

    constructor() {
      const listManager = new ListManager();
      const videoModalManager = new VideoModalManager();
      
      if (document.getElementById("bubble_chart").childElementCount < 1){ //avoid double load of all the charts (chart 5 because it is available for snapshot and tracking)
          this.user_id     = $("#data").data("user_id");
          this.is_snapshot = $("#data").data("snapshot");

          this.newLikes = [];
          this.reelsNewLikes = [];
          this.newComments = [];
          this.reelsNewComments = [];
          this.reelsNewViews = [];

          this.bindEvents();

          this.showEngagementRateChart("#chart3");
          
          if (!this.is_snapshot){
              this.showReelChart("#chart4");
          }
          
          this.ShowRepartitionPie("#chart5");
      }
          this.init_swiper();
    }

    showFollowingAndFollowers(graphId, newFollowers, newFollowings, markers_json, labels, start_date) {  
      var self = this;
      var w = window.innerWidth;
      var tickamount = 30;

      var annotation_orientation = self.getAnnotationOrientation(labels, start_date)

      if (w < 1500){
        tickamount = 15;
      }
      if (w < 600){
        tickamount = 10;
      }
      if (w < 500){
        tickamount = 5;
      }

      var options = {
          series: [{
          name: 'Abonnés',
          data: newFollowers
        }, {
          name: 'Abonnements',
          data: newFollowings
        }],
          chart: {
            height: 450,
            type: 'area',
            toolbar: {
              show: false
            },
            zoom: {
                enabled: false,
            },
            events: {
              dataPointSelection: (event, chartContext, config) => {
                var serie = 0;
                if(config.selectedDataPoints[0] == null){
                  serie = 1;
                }else{
                  serie = 0;
                }

                var url = "/users/"+this.user_id+"/posts-and-stories-by-day?date=" + labels[config.selectedDataPoints[serie]].replace(/\//g, "-")

                $.ajax({
                  url: url,
                  method: "GET",
                  dataType: "json"
                })
                .done(function (response) {
                  var posts = response.posts;
                  var posts_eng_rates = response.posts_eng_rates;
                  var stories_thumbnails = response.stories_thumbnails
                  var previous_date = response.previous_date
                  var previous_day_posts_count = response.previous_day_posts_count
                  var previous_day_stories_count = response.previous_day_stories_count

                  //var previous_day_posts = response.previous_day_posts;
                  //var previous_day_stories = response.previous_day_stories;
                  
                  var html = "<div class='container'>";
                  if (posts.length > 0){
                    html += "<h5>Publications</h5><div class='d-flex w-100' style='overflow-x: scroll;overflow-y: hidden;white-space: nowrap;'>";
                    for(var i = 0; i < posts.length; i++)
                    { 
                      $.ajax({
                        url: "/users/" + self.user_id + "/post-html?post_id=" + posts[i].id,
                        method: "GET",
                        dataType: "html",
                        async: false,
                      }).done(function (response) {
                        html+=response;
                      });
                    }
                    html += '</div></div>';
                  }
                  
                  if (stories_thumbnails.length > 0){
                    if(posts.length > 0){
                      html += "<hr>";
                    }
                    html += "<h5>Stories</h5><div class='d-flex w-100' style='overflow-x: scroll;overflow-y: hidden;white-space: nowrap;'>";
                    for(var i = 0; i < stories_thumbnails.length; i++)
                    {
                      html += "<div class='mt-2 mb-4 ml-3'>"
                      html += '<img src="' + stories_thumbnails[i] + '" style="border-radius:14px; height:400px; width:250px" alt="story image">';
                      html += "</div>";
                    }
                    html += '</div>';
                  }

                  if (stories_thumbnails.length + posts.length < 1){
                    html += "<div class='row'><h5>Nous n'avons pas trouvé de publications ni de stories pour ce jour</h5></div>";
                    
                    if(previous_day_posts_count > 0){
                      html += "<div class='row'><div class='col'>";
                      html += "<a href='/users/" + self.user_id + "/posts?posted_at=" + previous_date + "' target='blank__' class='btn btn-primary btn-block'>Voir les publications de la veille</a>";
                      html += "</div>";
                    }

                    if(previous_day_stories_count > 0){
                      html += "<div class='col'>";
                      html += "<a href='/users/" + self.user_id + "/stories?posted_at=" + previous_date + "' target='blank__' class='btn btn-primary btn-block'>Voir les stories de la veille</a>";
                      html += "</div></div>";
                    }

                    if ($( "#modal-dialog" ).hasClass('modal-lg')) {
                      $( "#modal-dialog" ).removeClass( 'modal-lg');
                    }
                  }else{
                    if (!$( "#modal-dialog" ).hasClass('modal-lg')) {
                      $( "#modal-dialog" ).addClass( 'modal-lg');
                    }
                  }
                  
                  html+="</div>";

                  if(posts.length > 0 && stories_thumbnails.length > 0){
                    $('#anomalyModal .modal-body').css({'overflow-x':'auto'}).css({'height':'auto'});
                    $('#anomalyModal .modal-body').css({'overflow-x':'scroll'}).css({'height':'600px'});
                  }else{
                    $('#anomalyModal .modal-body').css({'overflow-x':'auto'}).css({'height':'auto'});
                  }

                  $('#anomalyModal .modal-title').html("Éléments publiés le " + labels[config.selectedDataPoints[serie]]);
                  $('#anomalyModal .modal-body').html(html);

                  $('.post-item').off('click').on('click', function() {
                    $("#anomalyModal").modal('hide');
                  });

                  $('#anomalyModal').modal('show');

                  $(".confirmation-modal").on('hidden.bs.modal', function () {
                    $('#anomalyModal .modal-body').empty();
                  });
                })
              }
            }
        },
        dataLabels: {
          enabled: false
        },
        markers: {
          discrete: markers_json
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
            type: 'category',
            categories: labels,
            tickAmount: tickamount,
            labels: {
                rotate: -45,
                rotateAlways: true,
                hideOverlappingLabels: true,
            }
        },
        legend: {
          fontSize: "16px",
          offsetY: 5,
          markers: {
              width: 18,
              height: 18,
              radius: 12
          }
        },
        annotations: {
          xaxis: [{
            borderColor: '#999',
            x: labels[labels.indexOf(start_date)],
            label: {
              show: true,
              orientation: annotation_orientation,
              text: 'Début du tracking',
              style: {
                color: "#ffffff",
                background: '#ffffff'
              }
            }
          }]
        }
      };

      const chart = new ApexCharts(document.querySelector(graphId), options);
      chart.render();
    }

    showScoreChart(graphId, rate_evolution, labels) {
      var self = this;

      var options = {
          series: [{
          name: 'Score',
          data: rate_evolution
        }],
          chart: {
            height: 450,
            type: 'area',
            toolbar: {
              show: false
            },
            zoom: {
              enabled: false,
            },
          },
          colors: ['#f26993'],
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'smooth'
          },
          xaxis: {
            type: 'category',
            categories: labels,

            labels: {
              rotate: -45,
              rotateAlways: true,
              hideOverlappingLabels: true,
            }
          },
          legend: {
            fontSize: "16px",
            markers: {
                width: 18,
                height: 18,
                radius: 12
            }
          },
        };

        const chart = new ApexCharts(document.querySelector(graphId), options);
        chart.render();
    }

    showLikesAndComments(graphId, newLikes, newComments, labels, start_date, posts) {
      var self = this;
      
      var bar_radius = 12;
      var bar_width = '55%';

      var annotation_orientation = self.getAnnotationOrientation(labels, start_date)

      let annotations = [];

      if ($("#publications-switch-lc").is(':checked')) {
        for (let i = 0; i < posts.length; i++) {
          annotations.push(self.getMediaAnnotation(labels[labels.indexOf(posts[i].posted_at)], posts[i].title));
        }
      }

      annotations.push({
        borderColor: '#999',
        x: labels[labels.indexOf(start_date)],
        label: {
          show: true,
          orientation: annotation_orientation,
          text: 'Début du tracking',
          style: {
            color: "#ffffff",
            background: '#ffffff'
          }
        }
      });

      switch (labels.length) {
          case 7:
            bar_radius = 12;
            bar_width = '55%';
            break;
          case 14:
            bar_radius = 8;
            bar_width = '55%';
            break;
          case 30:
            bar_radius = 4;
            bar_width = '80%';
            break;
          case 60:
            bar_width = '90%';
          case 90:
            bar_width = '100%';
          default:
            bar_radius = 0;
        }
        var options = {
            series: [{
            name: 'Likes',
            data: newLikes,
            color: '#50C6E1'
        }, {
            name: 'Commentaires',
            data: newComments,
            color: '#1ABC9C'
        }],
            chart: {
            type: 'bar',
            height: 350,
            offsetY: 10,
            toolbar: {
                show: true,
                tools: {
                    download: false,
                } 
            }
        },
        legend: {
            show: true,
            fontSize: '16px',
            markers: {
                width: 12,
                height: 12,
                radius: 12
            }
        },
        plotOptions: {
            bar: {
            horizontal: false,
            columnWidth: bar_width,
            endingShape: 'rounded',
            borderRadius: bar_radius
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: labels,
            tickAmount: 15,
            labels: {
                rotate: -45,
                rotateAlways: true,
                hideOverlappingLabels: true,
            }
        },
        fill: {
            opacity: 1
        },
        annotations: {
          xaxis: annotations
        }
        };

        const chart = new ApexCharts(document.querySelector(graphId), options);

        chart.render();

        var current_type = null;
      
        $(".total-btn").on("click", function(e) {
          current_type = null;
          $(".like-and-comments-widget-subtitle").text("Engagement global");

          chart.updateSeries([{
            name: 'Likes',
            data: self.getTotal(self.newLikes, self.reelsNewLikes),
            color: '#50C6E1'
          }, {
            name: 'Commentaires',
            data: self.getTotal(self.newComments, self.reelsNewComments),
            color: '#1ABC9C'
          }], true);

          chart.clearAnnotations();

          if ($("#publications-switch-lc").is(':checked')) {
            for (let i = 0; i < posts.length; i++) {
              chart.addXaxisAnnotation(self.getMediaAnnotation(labels[labels.indexOf(posts[i].posted_at)], posts[i].title));
            }
          }
        });

        $(".posts-btn").on("click", function(e) {
          current_type = 'posts';
          $(".like-and-comments-widget-subtitle").text("Engagement des publications");

          chart.updateSeries([{
            name: 'Likes',
            data: self.newLikes,
            color: '#50C6E1'
          }, {
            name: 'Commentaires',
            data: self.newComments,
            color: '#1ABC9C'
          }], true);

          chart.clearAnnotations();

          if ($("#publications-switch-lc").is(':checked')) {
            for (let i = 0; i < posts.length; i++) {
              if (posts[i].post_type != 'post_type_reel'){
                chart.addXaxisAnnotation(self.getMediaAnnotation(labels[labels.indexOf(posts[i].posted_at)], posts[i].title));
              }
            }
          }
        });
        
        $(".reels-btn").on("click", function(e) {
          current_type = 'reels';
          $(".like-and-comments-widget-subtitle").text("Engagement des reels");

          chart.updateSeries([{
            name: 'Vues',
            data: self.reelsNewViews,
            color: '#fe5f94'
          }, {
            name: 'Likes',
            data: self.reelsNewLikes,
            color: '#50C6E1'
          }, {
            name: 'Commentaires',
            data: self.reelsNewComments,
            color: '#1ABC9C'
          }], true);
          
          chart.clearAnnotations();

          if ($("#publications-switch-lc").is(':checked')) {
            for (let i = 0; i < posts.length; i++) {
              if (posts[i].post_type == 'post_type_reel'){
                chart.addXaxisAnnotation(self.getMediaAnnotation(labels[labels.indexOf(posts[i].posted_at)], posts[i].title));
              }
            }
          }
        });

        $("#publications-switch-wrapper").on("click", function(e) {
          $(".publication-switch-label").trigger("click");
        });

        $("#publications-switch-lc").on("change", function(e) {
          if (this.checked) {
            for (let i = 0; i < posts.length; i++) {
              if (posts[i].post_type == 'post_type_reel' && current_type == 'reels'){
                chart.addXaxisAnnotation(self.getMediaAnnotation(labels[labels.indexOf(posts[i].posted_at)], posts[i].title));
              }else if (posts[i].post_type != 'post_type_reel' && current_type == 'posts'){
                chart.addXaxisAnnotation(self.getMediaAnnotation(labels[labels.indexOf(posts[i].posted_at)], posts[i].title));
              }else if (current_type == null){
                chart.addXaxisAnnotation(self.getMediaAnnotation(labels[labels.indexOf(posts[i].posted_at)], posts[i].title));
              }
            }
          } else {
            chart.clearAnnotations();
          }

          chart.addXaxisAnnotation({
            borderColor: '#999',
            x: labels[labels.indexOf(start_date)],
            label: {
              show: true,
              orientation: annotation_orientation,
              text: 'Début du tracking',
              style: {
                color: "#ffffff",
                background: '#ffffff'
              }
            }
          });
        });
        
      }

    showEngagementRateChart(graphId) {
        var position    = $(graphId).data("position");
        var grade    = $(graphId).data("grade");
        var color    = $(graphId).data("color");

        var options = {
            series: [position],
            chart: {
            height: 350,
            type: 'radialBar',
          },
          plotOptions: {
            radialBar: {
              startAngle: -140,
              endAngle: 140,
              dataLabels: {
                name: {
                  show: true,
                  fontColor: '#00000',
                  fontSize: '32px',
                  fontWeight: 'bold',
                  offsetY: 6
                },
                value:{
                  show: false
                }
              },
              track: {
                show: true,
              },
              }
          },
          labels: [grade],
          colors: [color],
          legend: {
            show: false,
          },
        };
  
        const chart = new ApexCharts(document.querySelector(graphId), options);
        chart.render();
    }

    showReelChart(graphId){
        var avg = $(graphId).data("avg-reel-reach");
        
        var options = {
            series: [avg],
            chart: {
            height: 350,
            type: 'radialBar',
          },
          plotOptions: {
            radialBar: {
              startAngle: -140,
              endAngle: 140,
              dataLabels: {
                value: {
                  show: true,
                  fontSize: '34px',
                  fontWeight: 'bold',
                  offsetY: -20
              },
                name:{
                  show: true,
                  offsetY: 30,
                  fontSize: '20px'
                }
              },
              track: {
                show: true,
              },
              }
          },
          labels: ['Vues'],
          colors: ['#FFCE5E'],
          legend: {
            show: false,
          },
        };
  
        const chart = new ApexCharts(document.querySelector(graphId), options);
        chart.render();
    }

    ShowRepartitionPie(graphId){
        var medias = $(graphId).data("media");
        
        var options = {
            series: medias,
            chart: {
            type: 'pie',
            height: 350
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            fontSize: '20px',
            offsetY: 10,
        },
        labels: ['Photo', 'Album', 'Reel'],
        colors: ['#2C67E4', "#3AAFDA", "#ffce5e"]
        };
    
        const chart = new ApexCharts(document.querySelector(graphId), options);
        chart.render();
    }

    showBubbleChart(graphId, data){
        var data_photos = [];
        var data_albums = [];
        var data_reels = [];
        var data_igtv = [];
        for (var i = 0; i < data.length; i++) {
            switch (data[i][3]){
                case 'post_type_picture':
                    if(data[i][2] > 1){
                        data[i][3] = 'photos';
                    }else{
                        data[i][3] = 'photo';
                    }
                    data_photos.push(data[i]);
                    break;
                case 'post_type_album':
                    if(data[i][2] > 1){
                        data[i][3] = 'albums';
                    }else{
                        data[i][3] = 'album';
                    }
                    data_albums.push(data[i]);
                    break;
                case 'post_type_reel':
                    if(data[i][2] > 1){
                        data[i][3] = 'reels';
                    }else{
                        data[i][3] = 'reel';
                    }
                    data_reels.push(data[i]);
                    break;
            }
        }

        var labels = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];
      
        var options = {
            series: [{
            name: 'Photos',
            data: data_photos,
            color: '#2C67E4'
          },
          {
            name: 'Album',
            data: data_albums,
            color: '#3AAFDA'
          },
          {
            name: 'Reel',
            data: data_reels,
            color: '#ffce5e'
          }],
            chart: {
                height: 350,
                offsetY: 20,
                type: 'bubble',
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false,
                }
            },
          dataLabels: {
              enabled: false
          },
          legend: {
            show: true,
            fontSize: '16px',
            markers: {
                width: 12,
                height: 12,
                radius: 12
            }
          },
          fill: {
            opacity: 0.8
          },
          xaxis: {
            tickAmount: 6,
            categories: labels
          },
          yaxis: {
            tickAmount: 12,
            max: 23
          },
          annotations: {
            yaxis: [{
              y: 0,
              y2: 6,
              borderColor: '#000',
              fillColor: '#fff',
              opacity: 0.1,
              label: {
                borderColor: '#333',
                style: {
                  fontSize: '10px',
                  color: '#333',
                  background: '#fff',
                },
                text: 'Nuit',
              }
            },
            {
              y: 6,
              y2: 12,
              borderColor: '#000',
              fillColor: '#fff',
              opacity: 0.1,
              label: {
                borderColor: '#333',
                style: {
                  fontSize: '10px',
                  color: '#333',
                  background: '#fff',
                },
                text: 'Matin',
              }
            },
            {
              y: 12,
              y2: 18,
              borderColor: '#000',
              fillColor: '#fff',
              opacity: 0.1,
              label: {
                borderColor: '#333',
                style: {
                  fontSize: '10px',
                  color: '#333',
                  background: '#fff',
                },
                text: 'Après-midi',
              }
            },
            {
              y: 18,
              y2: 24,
              borderColor: '#000',
              fillColor: '#fff',
              opacity: 0.1,
              label: {
                borderColor: '#333',
                style: {
                  fontSize: '10px',
                  color: '#333',
                  background: '#fff',
                },
                text: 'Soir',
              }
            }
            ]},
          tooltip: {
            custom: function({series, seriesIndex, dataPointIndex, w}) {
                var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                var wrd = null;
                //aberrant
                if(data[3] == 'photo' || data[3] == 'vidéo' ){
                    wrd = 'postée'
                }else if (data[3] == 'photos' || data[3] == 'vidéos'){
                    wrd = 'postées'
                }else if (data[3] == 'reel' || data[3] == 'album' || data[3] == 'igtv'){
                    wrd = 'posté'
                }else if (data[3] == 'reels' || data[3] == 'albums'){
                    wrd = 'postés'
                }

                if (data[1].toString().length < 2){
                    data[1] = '0' + data[1];
                }

                return '<div>' + 
                '<h5 style="padding: 1px 5px 0px 5px;">' 
                + data[2] + " " + data[3] + " " 
                + wrd + ' le ' + labels[data[0]-1].toLowerCase() 
                +  ' à ' + data[1] + 'h</h5>' +
                '</div>';
            }
        }
        };
  
        const chart = new ApexCharts(document.querySelector(graphId), options);
        chart.render();
    }

    showPostfrequencyChart(graphId, data, labels){
        var data_stories = data[0].reverse();
        var data_posts = data[1].reverse();
        labels = labels.reverse();

        var options = {
            series: [{
            name: 'Publications',
            data: data_posts,
            color: '#50C6E1'
        }, {
            name: 'Stories',
            data: data_stories,
            color: '#1ABC9C'
        }],
            chart: {
            type: 'bar',
            height: 350,
            offsetY: 20,
            toolbar: {
                show: false,
                tools: {
                    download: false,
                } 
            }
        },
        legend: {
            show: true,
            fontSize: '16px',
            markers: {
                width: 12,
                height: 12,
                radius: 12
            }
        },
        plotOptions: {
            bar: {
            horizontal: false,
            columnWidth: '55%',
            borderRadius: 12
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: labels
        },
        fill: {
            opacity: 1
        }
        };

        const chart = new ApexCharts(document.querySelector(graphId), options);
        chart.render(); 
    }

    showPostAnomaliesChart(graphId, posts, likes_limits, comments_limits,  labels) {
      var self = this;
      var likes_and_comments_limit = [likes_limits[0] + comments_limits[0], likes_limits[1] + comments_limits[1]];
      
      var likes_and_comments = [];
      var comments = [];

      for (var i = 0; i < posts.length; i++) {
        if (posts[i].likes > 0 && posts[i].comments > 0) {
          likes_and_comments.push([labels.indexOf(posts[i].date)+1, posts[i].likes + posts[i].comments]);
        }else{
          likes_and_comments.push([labels.indexOf(posts[i].date)+1, null]);
        }
      }
      
      var stats_anomalies_pos = [];

      for (var i = 0; i < likes_and_comments.length; i++) {
        if (likes_and_comments[i] != null && (likes_and_comments[i][1] > likes_and_comments_limit[0] || likes_and_comments[i][1] < likes_and_comments_limit[1])) {
          stats_anomalies_pos.push(i);
        }
      }

      var markers = "[";
      for (var i = 0; i < likes_and_comments.length; i++) {
        if (!stats_anomalies_pos.includes(i)){
          markers += '{"seriesIndex": ' + 0 + ',"dataPointIndex":' +  i + ',"fillColor": "#ffffff","strokeColor": "#cececece","size": 7,"shape": "circle"},';
        }else{
          markers += '{"seriesIndex": ' + 0 + ',"dataPointIndex":' +  i + ',"fillColor": "#ffffff","strokeColor": "#FF0000","size": 7,"shape": "circle"},';
        }
      }

      markers = markers.slice(0, -1);
      markers += "]";
      markers = JSON.parse(markers);
      var options = {
          series: [{
          name: 'Likes et commentaires',
          data: likes_and_comments
        }],
          chart: {
            height: 450,
            type: 'scatter',
            toolbar: {
              show: false
            },
            zoom: {
                enabled: false,
            },
            events: {
              dataPointSelection: (event, chartContext, config) => {                
                var serie = 0;
                if(config.selectedDataPoints[0] == null){
                  serie = 1;
                }else{
                  serie = 0;
                }

                var url = "/users/"+this.user_id+"/posts-and-stories-by-day?date=" + posts[config.dataPointIndex].date.replace(/\//g, "-")

                $.ajax({
                  url: url,
                  method: "GET",
                  dataType: "json"
                })
                .done(function (response) {
                  var r_posts = response.posts;
                  var posts_eng_rates = response.posts_eng_rates;
                  var stories_thumbnails = response.stories_thumbnails
                  var previous_date = response.previous_date
                  var previous_day_posts_count = response.previous_day_posts_count
                  var previous_day_stories_count = response.previous_day_stories_count

                  var html = "<div class='container'>";
                  if (r_posts.length > 0){
                    html += "<h5>Publications</h5><div class='d-flex w-100' style='overflow-x: scroll;overflow-y: hidden;white-space: nowrap;'>";
                    for(var i = 0; i < r_posts.length; i++)
                    { 
                      $.ajax({
                        url: "/users/" + self.user_id + "/post-html?post_id=" + r_posts[i].id,
                        method: "GET",
                        dataType: "html",
                        async: false,
                      }).done(function (response) {
                        html+=response;
                      });
                    }
                    html += '</div></div>';
                  }
                  
                  if (stories_thumbnails.length > 0){
                    if(posts.length > 0){
                      html += "<hr>";
                    }
                    html += "<h5>Stories</h5><div class='d-flex w-100' style='overflow-x: scroll;overflow-y: hidden;white-space: nowrap;'>";
                    for(var i = 0; i < stories_thumbnails.length; i++)
                    {
                      html += "<div class='mt-2 mb-4 ml-3'>"
                      html += '<img src="' + stories_thumbnails[i] + '" style="border-radius:14px; height:400px; width:250px" alt="story image">';
                      html += "</div>";
                    }
                    html += '</div>';
                  }

                  html+="</div>";

                  if(r_posts.length > 0 && stories_thumbnails.length > 0){
                    $('#anomalyModal .modal-body').css({'overflow-x':'auto'}).css({'height':'auto'});
                    $('#anomalyModal .modal-body').css({'overflow-x':'scroll'}).css({'height':'600px'});
                  }else{
                    $('#anomalyModal .modal-body').css({'overflow-x':'auto'}).css({'height':'auto'});
                  }

                  $('#anomalyModal .modal-title').html("Éléments publiés le " + posts[config.dataPointIndex].date);
                  $('#anomalyModal .modal-body').html(html);

                  $('.post-item').off('click').on('click', function() {
                    $("#anomalyModal").modal('hide');
                  });

                  $(".media-modal").on('hidden.bs.modal', function () {
                    $('#anomalyModal').modal('show');
                  });

                  $(".media-modal").on('hidden.bs.modal', function () {
                    $('#anomalyModal').modal('show');
                  });

                  $('#anomalyModal').modal('show');

                  $(".confirmation-modal").on('hidden.bs.modal', function () {
                    $('#anomalyModal .modal-body').empty();
                  });
                })
              }
            }
        },
        colors: ['#d993d1', '#c23eb2'],
        dataLabels: {
          enabled: false
        },
        markers: {
          discrete: markers
        },
        stroke: {
          curve: 'hidden'
        },
        xaxis: {
            type: 'category',
            categories: labels,
            tickAmount: 30,
            labels: {
                rotate: -45,
                rotateAlways: true,
                hideOverlappingLabels: true,
            }
        },
        annotations: {
          yaxis: [{
            y: likes_and_comments_limit[0],
            y2: likes_and_comments_limit[1],
            borderColor: '#FF0000',
            fillColor: '#eba0a0',
            opacity: 0.4,
            label: {
              borderColor: '#eba0a0',
              style: {
                fontSize: '10px',
                color: '#eba0a0',
                background: '#eba0a0',
              },
              text: 'Moyenne de likes + commentaires',
              style: {
                background: '#fff',
                color: '#777',
                fontSize: '12px',
                fontWeight: 400,
                padding: {
                  left: 5,
                  right: 5,
                  top: 2,
                  bottom: 2,
                }
              }
            }
          } 
        ]
        },
        legend: {
          fontSize: "16px",
          offsetY: 5,
          markers: {
              width: 18,
              height: 18,
              radius: 12
          }
        },
      };
      const chart = new ApexCharts(document.querySelector(graphId), options);
      chart.render();
    }
        

    bindEvents() {
        var self = this;

        if (!self.is_snapshot){
            $.ajax({
                url: "/users/"+this.user_id+"/stats_period",
                method: "GET",
                dataType: "json",
            })
            .done(function (response) {

                var newFollowers = [];
                var newFollowings = [];
                var followersAnomalies = [];
                var followingsAnomalies = [];
                var rate_evolution = [];
                var labels = response.labels
                var start_date = response.start_date;
                var posts = response.posts;

                var anomalies = [];

                response.data.forEach((item, index) => {
                  newFollowers.push(item.new_followers);
                  newFollowings.push(item.new_following);
                  
                  self.newLikes.push(item.new_likes);
                  self.newComments.push(item.new_comments);

                  self.reelsNewLikes.push(item.reels_new_likes);
                  self.reelsNewComments.push(item.reels_new_comments);
                  self.reelsNewViews.push(item.reels_new_views);

                  rate_evolution.push(item.rate);
                  
                  if(item.follower_anomaly == 1){
                    followersAnomalies.push(index);
                  }

                  if(item.following_anomaly == 1){
                    followingsAnomalies.push(index);
                  }

                  anomalies = [followersAnomalies, followingsAnomalies];
                });

                self.showScoreChart("#score_chart", rate_evolution, labels);

                self.showLikesAndComments("#chart", self.getTotal(self.newLikes, self.reelsNewLikes), self.getTotal(self.newComments, self.reelsNewComments), labels, start_date, posts);

                var anomalies_json = "";
                if (anomalies.length > 0 && anomalies[0].length > 0){
                  anomalies_json = "[";
                  for (var i = 0; i < anomalies.length; i++){
                    var fillColor = '"#ffffff"';
                    var strokeColor = '"#FF0000"';
                    if (i > 0){
                      strokeColor = '"#008000"';
                    }
                    for (var k = 0; k < anomalies[i].length; k++){
                      anomalies_json += '{"seriesIndex": ' + i + ',"dataPointIndex":' +  anomalies[i][k] + ',"fillColor": ' + fillColor + ',"strokeColor": ' + strokeColor + ',"size": 7,"shape": "circle"},';
                    }
                  }

                  anomalies_json = anomalies_json.slice(0, -1);
                  anomalies_json += "]";
                  anomalies_json = JSON.parse(anomalies_json);
                }

                self.showFollowingAndFollowers("#chart2", newFollowers, newFollowings, anomalies_json, labels, start_date);
            });
        }

        $.ajax({
            url: "/users/"+this.user_id+"/stats_posts",
            method: "GET",
            dataType: "json",
        })
        .done(function (response) {
            var posts_data = response.data;
            var post_data_array = []

            for (var key in posts_data) {
                for (var i = 0; i < Object.keys(posts_data[key]).length; i++){
                    post_data_array.push([posts_data[key][i].day, posts_data[key][i].hour, posts_data[key][i].amount, key]);
                }
            }
            
            self.showBubbleChart("#bubble_chart", post_data_array);
        })

        $.ajax({
            url: "/users/"+this.user_id+"/stats_posting_frequency",
            method: "GET",
            dataType: "json",
        })
        .done(function (response) {
            var data = response.data;
            var labels = data.months;
            var data_stories = [];
            var data_posts = [];
            for (var i = 0; i < Object.keys(data.stats).length; i++) {
                data_stories.push(data.stats[i].stories_count);
                data_posts.push(data.stats[i].posts_count);
            }
            
            const chart_data = [data_stories, data_posts]
            self.showPostfrequencyChart("#chart7", chart_data, labels);
        })
        
        $(".timing-link").off("click").click(function(e) {
            var link = $(this).data("href");

            Turbolinks.visit(link);

        });

        $.ajax({
            url: "/users/"+this.user_id+"/posts-anomalies",
            method: "GET",
            dataType: "json",
        })
        .done(function (response) {          
          if (response.limits != null){
            self.showPostAnomaliesChart("#chart_anomalies", response.posts, [response.limits.likes_upper_limit, response.limits.likes_lower_limit], [response.limits.comments_upper_limit, response.limits.comments_lower_limit], response.labels);
          }else{
            $(".chart-card").removeClass("d-block").addClass("d-none");
            $(".chart-placeholder").removeClass("d-none").addClass("d-block");
          }
        });

        $(".switch-chart").on("click", function(e) {
          $(".a-cards").removeClass("d-block").addClass("d-none");
          $(".a-chart").removeClass("d-none").addClass("d-block");
          $(".switch-cards").removeClass("btn-dark").addClass("btn-outline-dark");
          $(".switch-chart").removeClass("btn-outline-dark").addClass("btn-dark");
        });

        $(".switch-cards").on("click", function(e) {
          $(".a-cards").removeClass("d-none").addClass("d-block");
          $(".a-chart").removeClass("d-block").addClass("d-none");
          $(".switch-chart").removeClass("btn-dark").addClass("btn-outline-dark");
          $(".switch-cards").removeClass("btn-outline-dark").addClass("btn-dark");
        });

        $(".posts-btn").on("click", function(e) {
          self.handleButtonClick($(this));
          $("#posts-stats").removeClass("d-none").addClass("d-block d-lg-flex");
          $("#total-stats").removeClass("d-block d-lg-flex").addClass("d-none");
          $("#reels-stats").removeClass("d-block d-lg-flex").addClass("d-none");
        });
        
        $(".total-btn").on("click", function(e) {
          self.handleButtonClick($(this), $("#total-stats"));
          $("#total-stats").removeClass("d-none").addClass("d-block d-lg-flex");
          $("#posts-stats").removeClass("d-block d-lg-flex").addClass("d-none");
          $("#reels-stats").removeClass("d-block d-lg-flex").addClass("d-none");
        });
        
        $(".reels-btn").on("click", function(e) {
          self.handleButtonClick($(this), $("#reels-stats"));
          $("#reels-stats").removeClass("d-none").addClass("d-block d-lg-flex");
          $("#posts-stats").removeClass("d-block d-lg-flex").addClass("d-none");
          $("#total-stats").removeClass("d-block d-lg-flex").addClass("d-none");
        });

        $(".chart-posts, .chart-reels").removeClass("d-block").addClass("d-none");
    }

    init_swiper(){
        var self = this;

        if (!self.is_snapshot){
            const swiper = new Swiper('.swiper', {
                slidesPerView: 1,
                centeredSlides: true,
                spaceBetween: 30,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                on: {
                    slideChange: (swiper) => {
                        self.change_current_button(swiper.activeIndex);
                    }
                }
            });

            document
            .querySelector('.swiper-button-next')
            .addEventListener('click', function (e) {
                self.change_current_button(swiper.activeIndex);
            });
    
            document
            .querySelector('.swiper-button-prev')
            .addEventListener('click', function (e) {
                self.change_current_button(swiper.activeIndex);
            });
    
            document
            .querySelector('.slide-1')
            .addEventListener('click', function (e) {
                e.preventDefault();
                swiper.slideTo(0, 450);
                self.remove_active();
                document.querySelector('.slide-1').classList.add('active');
            });
    
            document
            .querySelector('.slide-2')
            .addEventListener('click', function (e) {
                e.preventDefault();
                swiper.slideTo(1, 450);
                self.remove_active();
                document.querySelector('.slide-2').classList.add('active');
            });
  
            document
            .querySelector('.slide-3')
            .addEventListener('click', function (e) {
                e.preventDefault();
                swiper.slideTo(2, 450);
                self.remove_active();
                document.querySelector('.slide-3').classList.add('active');
            });
    
            document
            .querySelector('.slide-4')
            .addEventListener('click', function (e) {
                e.preventDefault();
                swiper.slideTo(3, 450);
                remove_active();
                document.querySelector('.slide-4').classList.add('active');
            });
        }
    }

    remove_active() {
      if(document.querySelector(".slide-1").classList.contains("active")){
      document.querySelector('.slide-1').classList.remove('active');
      }
      if(document.querySelector(".slide-2").classList.contains("active")){
      document.querySelector('.slide-2').classList.remove('active');
      }
      if(document.querySelector(".slide-3").classList.contains("active")){
      document.querySelector('.slide-3').classList.remove('active');
      }
      if(document.querySelector(".slide-4").classList.contains("active")){
      document.querySelector('.slide-4').classList.remove('active');
      }
    }

    change_current_button(index){
      this.remove_active();
      document.querySelector('.slide-'+(index+1)).classList.add('active');
    }

    numberWithSpaces(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    getTotal(firstArray, secondArray){      
      return firstArray.map(function (item, index) {
          return item + secondArray[index];
      });
    }

    handleButtonClick($button, $stat) {
      $(".posts-btn, .total-btn, .reels-btn").removeClass("btn-dark").addClass("btn-outline-dark");
      $button.removeClass("btn-outline-dark").addClass("btn-dark");
    }

    getAnnotationOrientation(labels, start_date){
      if (labels[labels.indexOf(start_date)] === labels[labels.length - 1]){
        return "vertical";
      }else{
        return "horizontal";
      }
    }

    getMediaAnnotation(x_position, title){
      return {
        borderColor: '#999',
        x: x_position,
        label: {
          show: true,
          orientation: 'vertical',
          text: title,
          style: {
            color: "#ffffff",
            background: '#ffffff'
          }
        }
      }
    }
}