import ApexCharts from 'apexcharts';
import ListManager from "../utils/ListManager";

export default class HashtagForm {
    constructor() {
        const listManager = new ListManager();

        this.bindEvents()
    }

    initGraph(data_posts, data_posts_with_parameter, labels) {
      var w = window.innerWidth;
      var tickamount = 30;

      if (w < 1500){
        tickamount = 15;
      }
      if (w < 600){
        tickamount = 10;
      }
      if (w < 500){
        tickamount = 5;
      }

      var options = {
        series: [{
        name: 'Nombre de posts',
        data: data_posts
      }, {
        name: 'Nombre de posts selon vos règles',
        data: data_posts_with_parameter
      }],
        chart: {
        height: 350,
        type: 'area',
        toolbar: {
          show: false
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      
      xaxis: {
        type: 'category',
        categories: labels,
        tickAmount: tickamount,
            labels: {
                rotate: -45,
                rotateAlways: true,
                hideOverlappingLabels: true,
            }
        },
      };

      var chart = new ApexCharts(document.querySelector("#stats_contents"), options);
      chart.render();
    }


    bindEvents() {
        var self = this;

        let url = "/hashtags/"+$("#stats_contents").data("hashtag")+'/stats_period'
        document.querySelector("#stats_contents").style.display = "none"
        document.querySelector("#stats-preloader").style.display = "block"
        $.ajax({
            url: url,
            method: "GET",
            dataType: "json",
        })
        .done(function (response) {
            document.querySelector("#stats-preloader").style.display = "none";
            document.querySelector("#stats_contents").style.display = "block";
            
            var labels = response.labels;
            
            var data_posts = [];
            var data_posts_with_parameter = [];

            response.values.forEach((item) => {
              var x = item[0].total
              data_posts.push(x);
            })

            response.rules.forEach((item) => {
                var y = item[0].total
                data_posts_with_parameter.push(y);
            })

            self.initGraph(data_posts, data_posts_with_parameter, labels)
        })
    }
}