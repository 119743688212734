export default class HashtagForm {


    constructor() {
        this.check_timeout = null;
        this.bindEvents();
    }


    bindEvents() {
        var self = this;

        // $("#instagram_hashtag_monitoring_hashtag_name").off("keyup").on("keyup", function(e) {
        //     self.check_hashtag();
        // });
    }

    onDataChanged(data) {
    }

    check_hashtag(){
        $("#hashtag_notification").removeClass("alert alert-danger alert-success").html("");

        $("#save_hashtag_btn").attr("disabled", true);
        clearTimeout(self.check_timeout);
        if ($("#instagram_hashtag_monitoring_hashtag_name").val() !== undefined){
            let hashtag_user = $("#instagram_hashtag_monitoring_hashtag_name").val().trim().replace('#','').replace('@','');

            if(hashtag_user !== "" && hashtag_user !== undefined){
                self.check_timeout = setTimeout(function () {
                    let ajaxData = {
                        url: "https://www.instagram.com/explore/tags/" + hashtag_user + "/?__a=1",
                        //url: "/ig_test_request/perpignan.json",
                        data: {},
                        method: "get",
                    };
                    $.ajax(ajaxData).done(function (data) {
                        if (typeof data === 'object' && data !== null) {
                            let hashtag = data.graphql.hashtag;
                            $("#save_hashtag_btn").attr("disabled", false);

                            $("#metadata_profile_picture_url").val(hashtag.profile_pic_url);
                            $("#metadata_media_count").val(""+hashtag.edge_hashtag_to_media.count);

                            $("#hashtag_notification").addClass("alert alert-success");
                            $("#hashtag_notification").html(
                                '<a href="https://www.instagram.com/explore/tags/'+ hashtag.name +'" target="_blank">' +
                                '   <img height="40" style="border-radius: 50%" src="'+hashtag.profile_pic_url+'">' +
                                '</a>' +
                                '<div style="vertical-align: top;padding-top: 7px;display: inline-block;margin-left: 15px">' +
                                '   <a href="https://www.instagram.com/explore/tags/'+ hashtag.name +'" target="_blank" class="green-text">' +
                                '       #'+ hashtag.name +'' +
                                '   </a>' +
                                '</div>'
                            );
                        }
                    }).fail(function (err) {
                        $("#save_hashtag_btn").attr("disabled", false);
                        //Hashtag doesn't exist
                        $("#hashtag_notification").addClass("alert alert-danger");
                        $("#hashtag_notification").html("Le hashtag recherché ne semble pas exister");
                    });
                }, 1000);

            }
        }

    }

}