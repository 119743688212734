export default class InfluencersCampaignDetails {

  constructor() {
    this.bindEvents();
  }

  bindEvents() {
    var content = $('#description').html();

    content = content.replace(/\*\*(.*?)\*\*/g, '<span class="font-weight-bold">$1</span>');
    content = content.replace(/\*(.*?)\*/g, '<em class="font-italic">$1</em>');
    content = content.replace(/_(.*?)_/g, '<span class="underline">$1</span>');

    $('#description').html(content);
  }

  onDestroy(){
  
  }
}