import Application from "../Application";

export default class InstagramAccountsIndex {

    constructor() {
        this.bindEvents();
        this.interval = null;
    }

    bindEvents() {

        let self = this;

        this.dataTable = $('table.display').DataTable({
            "info": false,
            "lengthChange": false,
            "language": {
                url: '/assets/data-tables/locales/' + I18n.locale + '.json',
                previous: "<i class='mdi mdi-chevron-left'>",
                next: "<i class='mdi mdi-chevron-right'>"
            },
            drawCallback: function () {
                $(".dataTables_paginate > .pagination").addClass("pagination-rounded")
            },
        });
        /*
        if (window.localStorage.index_order !== undefined) {
            let sort = window.localStorage.index_order.split(",")
            this.dataTable.order([sort[0], sort[1]])
        }
        if (window.localStorage.index_search !== undefined) {
            this.dataTable.search(window.localStorage.index_search)
        }*/
        this.dataTable.draw()

        $('.js-add-instagram-accounts').unbind('click').click(this.addInstagramAccounts)

        $('.js-load-2fa-modal-content').click(function(){
          let id = $(this).data("id");
          self.load2faModalContent(id);
          $("#btn_toggle_2fa_modal_"+id).prop('disabled', true);
        })

        $('.js-load-bot-activity-modal').click(this.loadBotActivityModal)

        $('.js-recuperation-code-modal').click(this.loadRecuperationCodeModal)

        $('.js-add-proxy-modal').click(this.loadAddProxyModal)

        $('.js-save-params').click(this.saveParams);

        $("#connect_account_btn").unbind("click").click(function(e) {
          $("#connect_account").modal("hide");
          $("#confirm_connect_account").modal();

          let accountID = $("#account_select").val();
          let uaID = $("#useragent_select").val();

          let accountName = $("#account_select option:selected").text();
          let uaName = $("#useragent_select option:selected").text();

          $("#confirm_username_label").html(""+accountName);
            $("#confirm_useragent_label").html(""+uaName);

            let link = $("#confirm_connect_btn").data("href");
            $("#confirm_connect_btn").attr("href", link.replace("__ACCOUNT_ID__", accountID).replace("__USER_AGENT_ID__", uaID));
        })

        $("#cancel_connect_btn").unbind("click").click(function(e) {
          $("#confirm_connect_account").modal("hide");
          $("#connect_account").modal();
        })

        $("#add_imap_address_button").unbind("click").click(function(e) {
            imapAdress = $("#imap_select").val();
            let link = $("#add_imap_address_button").data("href");
            link.replace("__IMAP_ADDRESS__", imapAdress);
        })

        $('#get_2fa_code_modal').on('hidden.bs.modal', function (event) {
            clearInterval(self.interval);
        })

    }

    loadBotActivityModal() {
        let id = $(this).data("id");
        let lock = $(this).data("lock");
        let state = $(this).data("state");
        let ajaxData = {
            url: "/administration/instagram_accounts/" + id + "/load_bot_activity_modal?redirect=index&lock=" + lock + "&state=" + state,
            method: "GET"
        };

        $.ajax(ajaxData).done(function(html) {
            $('.bot_activity_container').empty().append(html);
            $('#bot_activity_modal').modal("show");
        })
    }

    loadRecuperationCodeModal() {
        let id = $(this).data("id");
        let redirect = $(this).data("redirect")
        let ajaxData = {
            url: "/administration/instagram_accounts/" + id + "/load_recuperation_code" + "?redirect=" + redirect,
            method: "GET"
        };

        $.ajax(ajaxData).done(function(html) {
            $('#recuperation_code_modal_container').empty().append(html);
            $('#recuperation_code_modal').modal("show");
        })
    }

    loadAddProxyModal() {
      let id = $(this).data("id");
      let ajaxData = {
        url: "/administration/instagram_accounts/" + id + "/load_proxy_modal",
        method: "GET"
      }

      $.ajax(ajaxData).done(function(html) {
          $('#add_proxy_modal_content').empty().append(html);
          $('#add_proxy_modal_content select.select2-with-search').select2({
              placeholder: $('#add_proxy_modal_content select.select2-with-search').data("placeholder"),
              minimumResultsForSearch: 5
          });
          $('#add_proxy_modal').modal("show");
      })
    }

    load2faModalContent(id) {
      let self = this;
        let ajaxData = {
            url: "/administration/instagram_accounts/" + id + "/load_2fa_modal",
            method: "GET"
        };

        $.ajax(ajaxData).done(function(html) {
            $('#2fa_modal_container').empty().append(html);
            $('#get_2fa_code_modal').modal("show");
            // $("#btn_toggle_2fa_modal_"+id).tooltip("hide");
            $("#btn_toggle_2fa_modal_"+id).prop('disabled', false);
            let maxTime = 30
            var dt = new Date();
            var seconds = dt.getSeconds();
            var timeLeft = seconds%30;
            timeLeft = 30 - timeLeft;
            $("#2fa_time_left").text(""+timeLeft);


            self.interval = setInterval(function() { //fonction pour compteur
                if (timeLeft > 0 ){
                  timeLeft--;
                }
                // Display 'counter' wherever you want to display it.
                $("#2fa_time_left").text(""+timeLeft);
                if (timeLeft == 0) {
                  console.log("timeleft 0")
                    clearInterval(self.interval);
                    application.current_handler.load2faModalContent(id);
                }
            }, 1000);
        })

    }

    saveParams() {
        window.localStorage.index_order = application.current_handler.dataTable.order().join()
        window.localStorage.index_search = application.current_handler.dataTable.search()
    }

    redirectWithOptions() {
        let order = application.current_handler.dataTable.order()
        let url = new URL(window.location.href)
        let currents_params = "state=" + url.searchParams.get("state") + "&lock=" + url.searchParams.get("lock") + "&index_sort=";
        let id = $(this).data("id")
        let action = id != null ? "/" + id : $(this).data("action")
        window.location.href = "instagram_accounts/" + action + "?" + currents_params + order.join();
    }

    addInstagramAccounts() {

        function createInput(name, message) {
            let input = document.createElement('input');

            input.name = name;
            input.value = '';
            input.placeholder = message
            input.type = 'text';
            input.className = "form-control";

            input.style.marginBottom = "8px";

            return input;
        }

        function attach_and_hide_field(form, input) {
            form.appendChild(input);
            input.type = "hidden";
        }

        let self = application;
        let title = $(this).data("title");
        let btnPositive = $(this).data("btn-confirm");
        let btnCancel = $(this).data("btn-cancel");
        let url = $(this).data("url");

        let name_input = createInput("username", "Entrez le nom");
        let password_input = createInput("password", "Entrez le mot de passe");
        let server_input = createInput("server_name", "Entrez le nom du server");
        server_input.setAttribute("list", "datalist");

        let body = [
            "Nom",
            name_input,
            "Mot de passe",
            password_input,
            "Nom du server",
            server_input,
        ]

        self.confirmationModal.displayConfirm(
            title,
            body,
            {
                positive_btn: {
                    label: btnPositive,
                    callback: function() {
                        let form = document.createElement('form');
                        form.action = url;
                        form.method = "post"

                        let inputMethod = document.createElement('input');
                        inputMethod.name = '_method';
                        inputMethod.value = 'post'
                        inputMethod.type = 'hidden'
                        form.appendChild(inputMethod);

                        let inputToken = document.createElement('input');
                        inputToken.name = 'authenticity_token';
                        inputToken.value = $('meta[name="csrf-token"]').attr('content');
                        inputToken.type = 'hidden'
                        form.appendChild(inputToken);

                        attach_and_hide_field(form, name_input);
                        attach_and_hide_field(form, password_input);
                        attach_and_hide_field(form, server_input);

                        document.body.appendChild(form);
                        form.submit();
                    }
                },
                negative_btn: {
                    label: btnCancel
                }
            }
        )
    }

    onDataChanged(data) {
    }

    onDestroy() {
        this.dataTable.destroy();
        this.dataTable = null;
    }

}
