export default class HashtagPlaces {


    constructor() {

        this.initial_latitude = $("#places_map").data("initialLatitude");
        this.initial_longitude = $("#places_map").data("initialLongitude");
        this.base_place_detail_url = $("#places_map").data("basePlaceDetailUrl");
        let self = this;

        this.initMap();
        this.bindEvents();
    }


    bindEvents() {
    }

    onDestroy(){
        var self = this;
        this.markers.clearLayers();
        this.markers.off('clusterclick');
        this.map.invalidateSize();
        this.map.removeLayer(this.markers);
        this.map.eachLayer(function (layer) { self.map.removeLayer(layer); });
        this.markers.remove();
        this.map.remove();
        this.markers = null;
        this.map = null;
        console.log("onDestroy");
    }

    getDisplayNumber(number) {
        if (number > 9999) {
            let nbr_str = number.toString();
            return nbr_str.slice(0, -3) + "k";
        }
        return number;
    }

    initMap() {
        var self = this;

        $('#places_map').height(window.innerHeight/2);
        this.map = L.map('places_map', { "tap": false }).setView([this.initial_latitude, this.initial_longitude], 12);

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(this.map);

        var positions = []

        this.markers = L.markerClusterGroup({
            animate: true,
            iconCreateFunction: function(cluster) {
                var cluster_markers = cluster.getAllChildMarkers();
                var cluster_posts_count = 0;
                for (var i = 0; i < cluster_markers.length; i++) {
                    cluster_posts_count += cluster_markers[i].options.post_count;
                }
                var size = null
                if (cluster_posts_count > 100) {
                    size = 'large';
                }else if (cluster_posts_count > 10 && cluster_posts_count <= 100) {
                    size = 'medium';
                }else if (cluster_posts_count > 1 && cluster_posts_count <= 10) {
                    size = 'small';
                }
                
                return L.divIcon({
                    html: "<div><span>" + cluster_posts_count + "</span></div>",
                    className: 'da-cluster marker-cluster-'+size,
                    iconSize: null
                });
            }
        });
        
        $.each($('#places_map').data("places"), function(idx, location) {

            if(location.latitude!=null && location.longitude!=null) {

                let detailURL = self.base_place_detail_url.replace("__ID__", location.instagram_id);

                positions.push([location.latitude, location.longitude]);
                var marker = L.marker([location.latitude, location.longitude], {
                    post_count: location.post_count, 
                        icon: new NumberedDivIcon({number: self.getDisplayNumber(location.post_count) })
                }).bindPopup(
                    "<div class=\"text-center\">"+
                        "<b>"+((location.short_name==null || location.short_name.length==0) ? location.name : location.short_name)+"</b><br/>"+
                        "<a class=\"mt-2 text-white btn btn-primary\" href=\""+detailURL+"\">"+
                        (location.post_count>1 ? "VOIR LES "+location.post_count+" POSTS" : "VOIR LE POST")+
                        "</a>"+
                    "</div>"
                    )
                self.markers.addLayer(marker);
            }
        });

        this.map.addLayer(this.markers);

        if(positions.length>0) {
            var bounds = new L.LatLngBounds(positions);
            this.map.fitBounds(bounds);
        }
    }
}