import Application from "../Application";

export default class InstagramAccountsIndex {

    constructor() {
        this.bindEvents();
    }
    
    bindEvents() {
        this.dataTable = $('table.display').DataTable({
            "info": false,
            "lengthChange": false,
            "order": [1, 'asc'],
            "language": {
                url: '/assets/data-tables/locales/' + I18n.locale + '.json',
                previous: "<i class='mdi mdi-chevron-left'>",
                next: "<i class='mdi mdi-chevron-right'>"
            },
            drawCallback: function () {
                $(".dataTables_paginate > .pagination").addClass("pagination-rounded")
            },
        });
    }

    onDataChanged(data) {
    }

    onDestroy() {
        this.dataTable.destroy();
        this.dataTable = null;
    }

}