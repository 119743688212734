import Swiper, { Navigation, Pagination } from 'swiper';
Swiper.use([Navigation, Pagination]); 

export default class BackofficeAdministrationAdvertisingCampaignsParticipantsLinks {

  constructor() {
      this.bindEvents();
  }

  bindEvents() {
    let self = this;

    this.swiper = new Swiper(".post_swiper", {
      loop: true,
      pagination: {
        el: ".swiper-pagination",
        type: "fraction",
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  }

  onDataChanged(data) {
  }

  onDestroy() {
    if (this.swiper){
      this.swiper.destroy();
      this.swiper = null;
    }
  }
}
