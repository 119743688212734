import Routes from "./config/Routes";
import ConfigApplication from "../config/Application";
import ConfirmationModal from "./utils/ConfirmationModal";

export default class Application extends ConfigApplication{
    constructor() {
        super();
        this.routes = new Routes();
        this.confirmationModal = new ConfirmationModal();
        // $.ajaxSetup({
        //     headers: {
        //         'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        //     }
        // });
    }
    pageChanged() {
        super.pageChanged();

        this.onAlertChange();
        this.onNoticeChange();
        this.onErrorChange();
        this.onSuccessChange();


        $(".select2-nosearch").each(function(idx, node) {
            if($(node).next().hasClass('select2-container')) {
              $(node).next().remove();
            }
            $(node).select2({
                placeholder: $(node).data("placeholder"),
                minimumResultsForSearch: -1
            });
        });

        $(".select2-with-search").each(function(idx, node) {
            if($(node).next().hasClass('select2-container')) {
              $(node).next().remove();
            }
            $(node).select2({
                placeholder: $(node).data("placeholder"),
                minimumResultsForSearch: 5
            });
        });

        $(".select2-with-tag").each(function(idx, node) {
            if($(node).next().hasClass('select2-container')) {
              $(node).next().remove();
            }
            $(node).select2({
                placeholder: $(node).data("placeholder"),
                tags: true,
                "language":{
                  "noResults" : function () { return 'Effectuez votre sélection'; }
                }
            });
        });

        $(".stop-click-propagation").on("click", function(e) { e.stopPropagation(); });
    }

    bindEventsAfterPageChanged() {
        super.bindEventsAfterPageChanged();

        $('.js-delete-confirm').unbind('click').click(this.deleteConfirm)
        $('.js-field-modal').unbind('click').click(this.fieldModal)
    }

    onAlertChange(){
        if(window.warning !== null && window.warning !== undefined && window.warning !== '') {
            this.alertNotification(window.warning);
        }
    }

    onNoticeChange(){
        if(window.notice !== null && window.notice !== undefined && window.notice !== '') {
            this.noticeNotification(window.notice);
        }
    }

    onErrorChange(){
        if(window.error !== null && window.error !== undefined && window.error !== '') {
            this.errorNotification(window.error);
        }
    }
    onSuccessChange(){
        if(window.success !== null && window.success !== undefined && window.success !== '') {
            this.successNotification(window.success);
        }
    }

    successNotification(msg){
        $.NotificationApp.send("Succès!",msg,"top-right","#5ba035","success",5000,4)
    }

    errorNotification(msg){
        $.NotificationApp.send("Erreur!",msg,"top-right","#bf441d","error",5000,4)
    }

    noticeNotification(msg){
        $.NotificationApp.send("Information!",msg,"top-right","#3b98b5","info",5000,4)
    }

    alertNotification(msg){
        $.NotificationApp.send("Atention!",msg,"top-right","#da8609","warning",5000,4)
    }

    deleteConfirm(e) {
        e.preventDefault();
        let self = application
        let title = $(this).data("title");
        let message = $(this).data("message");
        let btnPositive = $(this).data("btn-confirm");
        let btnCancel = $(this).data("btn-cancel");
        let url = $(this).data("url");
        let id = $(this).data("id");
        self.confirmationModal.displayConfirm(
            title,
            message,
            {
                positive_btn: {
                    type: "danger",
                    label: btnPositive,
                    callback: function() {
                        let form = document.createElement('form');
                        form.style.visibility = 'hidden';
                        form.action = url;
                        form.method = 'post'

                        let input = document.createElement('input');
                        input.name = '_method';
                        input.value = 'delete';
                        input.type = 'hidden'
                        form.appendChild(input);

                        let inputToken = document.createElement('input');
                        inputToken.name = 'authenticity_token';
                        inputToken.value = $('meta[name="csrf-token"]').attr('content');
                        inputToken.type = 'hidden'
                        form.appendChild(inputToken);

                        document.body.appendChild(form);
                        form.submit();
                    }
                },
                negative_btn: {
                    label: btnCancel
                }
            });
    }

    fieldModal() {
        let self = application
        let title = $(this).data("title");
        let btnPositive = $(this).data("btn-confirm");
        let btnCancel = $(this).data("btn-cancel");
        let url = $(this).data("url");
        let method = $(this).data("method");

        let input = document.createElement('input');
        input.name = $(this).data("input-name")
        input.value = '';
        input.placeholder = $(this).data("message");
        input.type = 'text';
        input.className = "form-control";

        self.confirmationModal.displayConfirm(
            title,
            input,
            {
                positive_btn: {
                    label: btnPositive,
                    callback: function() {
                        let form = document.createElement('form');
                        form.action = url
                        form.method = 'post';

                        input.type = 'hidden'
                        form.appendChild(input)

                        let inputMethod = document.createElement('input');
                        inputMethod.name = '_method';
                        inputMethod.value = method;
                        inputMethod.type = 'hidden'
                        form.appendChild(inputMethod);

                        let inputToken = document.createElement('input');
                        inputToken.name = 'authenticity_token';
                        inputToken.value = $('meta[name="csrf-token"]').attr('content');
                        inputToken.type = 'hidden'
                        form.appendChild(inputToken);

                        document.body.appendChild(form);
                        form.submit();
                    }
                },
                negative_btn: {
                    label: btnCancel
                }
            }
        );
    }
}
